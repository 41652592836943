import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row, Stack, Table } from "react-bootstrap";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { ShimmerTable } from "react-shimmer-effects";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';

import jwt_decode from "jwt-decode";
import SparkApi from "../api/SparkApi";
import InfoPill from "./InfoPill";

const AccountList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem('token')));
    async function init() {
      const accounts = await SparkApi.fetchAccounts();
      if (accounts) {
        setBody(accounts);
      } else {
        setBody([]);
      }
    }
    init();
  }, []);

  // Create table headers consisting of 4 columns.
  const header = [];
  if (!isMobile) {
    header.push(
      {
        title: "Account Name",
        prop: "name",
        isFilterable: true,
        cell: (row) => (
          <Link to={"/accounts/" + row.id} state={row}>
            {row.name}
          </Link>
        ),
      },
      { title: "Street", prop: "street", isFilterable: true },
      { title: "City", prop: "city", isFilterable: true },
      { title: "Phone", prop: "phone", isFilterable: true },
      { title: "Email", prop: "email", isFilterable: true },
      { title: "Website", prop: "website", isFilterable: true },
      { title: "Area", prop: "area", isFilterable: true },
      { title: "Other Area", prop: "otherarea", isFilterable: true },
      {
        title: "Assigned",
        prop: "ownername",
        isFilterable: true,
        isSortable: true,
        cell: (row) => {
          const titleAbbreviations = {
            "Sales Admin": "S. A.",
            "Installation Admin": "I. A.",
            "Accounts Admin": "A. A.",
            "Installation Engineer": "I. E.",
            "Business Development Executive": "B. D. E.",
            "Customer Relationship Executive": "C. R. E.",
            "Office Coordinator": "O. C.",
            "Office Executive": "O. E.",
            "Super_Admin": "Super_Admin" // If no abbreviation is needed
          };


          // Split ownername and ownerid arrays
          const ownerNames = row.ownername ? row.ownername.split(', ') : [];
          const ownerIds = row.ownerid ? row.ownerid : [];

          // Function to shorten titles
          const shortenTitle = (name) => {
            const [fullName, title] = name.split(' - ');
            const shortTitle = titleAbbreviations[title.trim()] || title; // Use abbreviation if available
            return `${fullName} - ${shortTitle}`;
          };

          // Ensure ownerNames and ownerIds arrays are of the same length
          const links = ownerNames.map((name, index) => (
            <Link
              key={index} // Use index as key if ownerIds[index] is not reliable
              to={`/users/${ownerIds[index]}`}
              state={row}
              className="align-items-center justify-content-start"
            >
              {shortenTitle(name)}<br />
            </Link>
          ));
          return <span>{links}</span>;
        },

      })
  } else {
    //for mobile device

    header.push(
      {
        title: "Info",
        prop: "name",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <div className="mobilecard" >
            <Link to={"/accounts/" + row.id} state={row} style={{ width: "100%" }}>
              {row.name}
            </Link>

            <span><i class="fa-solid fa-phone"></i> {row.phone}</span>
            <span style={{ width: "80%" }}><i class="fa-solid fa-globe"></i>{row.website}</span>

          </div>
        ),
      },
    )
  }

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createAccount = () => {
    navigate(`/accounts/e`);
  };

  return (
    <Row className="g-0">

      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 10,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
              <Stack direction="horizontal">
                <PaginationOptions labels={labels} />
                <BrowserView className="d-flex align-items-end">
                  <InfoPill left="Total" right={body?.length} />
                </BrowserView>
              </Stack>
            </Col>

            {userInfo && userInfo?.userrole !== 'USER' && <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={() => createAccount(true)}
              >
                New Account
              </Button>
            </Col>}

          </Row>

          {body ? (
            <Table striped className="data-table" responsive="sm">
              <TableHeader />
              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}

          <Pagination />
        </DatatableWrapper>
      </Col>
    </Row>
  );
};
export default AccountList;
