/* Start updated by Pooja Vaishnav and it will show the list of all the products*/

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";

const ProductList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [products, setProducts] = useState(location.state ? location.state : {});

  useEffect(() => {
    async function init() {
      const products = await SparkApi.fetchProducts();
      if (products) {
        setBody(products);
        setProducts(products);
      } else {
        setBody([]);
        setProducts([]);
      }
    }
    init();
  }, []);

  const header = [];

  if (!isMobile) {
    header.push(
      {
        title: "Product Name",
        prop: "productname",
        isFilterable: true, isSortable: true,

        cell: (row) => (
          <Link to={"/products/" + row.id} state={row}>
            {row.productname}
          </Link>
        ),
      },
      { title: "Product Code", prop: "productcode", isFilterable: true },
      { title: "Category", prop: "category", isFilterable: true },
      { title: "Subcategory", prop: "subcategory", isFilterable: true },
      {
        title: "Created Date", prop: "createddate", isSortable: true, isFilterable: true, cell: (row) => (
          <span>
            {moment(row.createddate).format('DD-MM-YYYY')}
          </span>
        )
      },
    )
  } else {
    header.push(
      {
        title: "Info",
        prop: "productname",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <div className="mobilecard">
            <Link to={"/products/" + row.id} state={row} style={{ fontSize: "1.2rem" }}>
              {row.productname}
            </Link>
          </div>
        ),
      },
    )
  }

  const labels = {
    beforeSelect: " ",
  };

  const createProduct = () => {
    navigate(`/products/e`);
  };

  return (
    <Row className="g-0">
      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 15,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={5}
              className="d-flex flex-col justify-content-start align-items-center"
            >
              <PaginationOptions labels={labels} />
              <BrowserView>
                <InfoPill left="Total" right={body?.length} />
              </BrowserView>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={3}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary mx-2"
                onClick={() => createProduct(true)}
              >
                New Product
              </Button>
            </Col>
          </Row>
          {body ? (
            <Table striped className="data-table" responsive="sm">
              <TableHeader />

              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};
export default ProductList;
/* End updated by Pooja Vaishnav */