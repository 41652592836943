import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Form, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SparkApi from '../api/SparkApi';
import moment from 'moment';
import 'moment-timezone';

const EmployeeAttReport = () => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [attandancedata, setAttandancedata] = useState([]);
    const [tempdata, setTempdata] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
        fetchAllAttandanceRecords();
        fetchAllUsers();
    }, []);

    const fetchAllAttandanceRecords = async () => {
        let result = await SparkApi.fetchAllAttandanceRecords();
        console.log('result', result);
        let filterdata = result.filter(value => value.attandancetype === 'Employee Attendance');
        console.log('filterdata', filterdata);
        setTempdata(filterdata);
        setAttandancedata(filterdata);
    };

    const fetchAllUsers = async () => {
        try {
            const result = await SparkApi.fetchUsers();
            console.log('result users', result);
            setAllUsers(result);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleMonthChange = (date) => {
        setSelectedMonth(date);
        filterData(date, selectedUser);
    };

    const handleSelectUsers = (event) => {
        setSelectedUser(event.target.value);
        filterData(selectedMonth, event.target.value);
    };

    const filterData = (date, user) => {
        const dateToFilter = date ? moment(date) : null;
        const filterMonth = dateToFilter ? dateToFilter.month() : null;
        const filterYear = dateToFilter ? dateToFilter.year() : null;

        let filteredData = tempdata;
        // console.log("Filer month & year",date,filterMonth,filterYear)
        if (filterMonth !== null && filterYear !== null) {
            filteredData = filteredData.filter(value => {
                // console.log("filter Attendance = ",new Date(value.logindatetime),date);
                const loginMoment = moment(value.logindatetime);
                // console.log("filter Attendance = ",loginMoment.month(),filterMonth);
                return loginMoment.month() === filterMonth && loginMoment.year() === filterYear;
            });
        }

        if (user) {
            filteredData = filteredData.filter(
                value => `${value.firstname} ${value.lastname}` === user
            );
        }

        setAttandancedata(filteredData);
    };

    const formatISODateString = (isoString, timezone = 'Asia/Kolkata') => {
        const formattedDate = moment.tz(isoString, timezone).format('DD-MM-YYYY HH:mm:ss');
        return formattedDate;
    };

    return (
        <div className='container my-4'>
            <Card className='mb-4'>
                <Card.Body>
                    <h4>Employee Attendance</h4>
                    <Form>
                        <Row className="align-items-center">
                            <Col md={3}>
                                <Form.Group controlId="dateFilterMonth">
                                    <Form.Label className="ml-n3">Select Month:</Form.Label>
                                    <DatePicker
                                        selected={selectedMonth}
                                        onChange={handleMonthChange}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        className="form-control mx-2"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group controlId="dateFilterUser">
                                    <Form.Select value={selectedUser} onChange={handleSelectUsers}>
                                        <option value="">Select Employee</option>
                                        {allUsers.map(user => (
                                            <option key={user.id} value={`${user.firstname} ${user.lastname}`}>
                                                {user.firstname} {user.lastname}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={1}>
                                <Button variant="link" className="p-0 text-danger">
                                    <i className="bi bi-x-circle"></i>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>

            <Card>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <td>#</td>
                            <th>Employee Name</th>
                            <th>Location</th>
                            <th>Shift Start Date Time</th>
                            <th>Shift End Date Time</th>
                            {/* <th>Attendance Type</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {attandancedata.map((value, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{value.firstname} {value.lastname}</td>
                                <td>{value.location}</td>
                                <td>{value.logindatetime ?  moment.tz((value.logindatetime), 'Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A'): null}</td>
                                <td>{value.logoutdatetime ? moment.tz((value.logoutdatetime), 'Asia/Kolkata').format('DD-MM-YYYY hh:mm:ss A') : null}</td>
                                {/* <td>{value.attandancetype}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card>
        </div>
    );
};

export default EmployeeAttReport;
