import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import MonthWiseBarChart from './charts/MonthWiseBarChart';
import { Col, Container, Row, FormControl, FormGroup, FormLabel, FormSelect, Card, Stack, Button } from 'react-bootstrap';
import LeadCountByAgentChart from './charts/LeadCountByAgentChart';
import DueAmountChart from './charts/DueAmountChart';
import BusinessChart from './charts/BusinessChart';
import TaskActivityChart from './charts/TaskActivityChart';
import DayWiseMeetingChart from './charts/DayWiseMeetingChart';
import ProductWiseSalesChart from './charts/ProductWiseSalesChart';
import LeadCountByStages from './charts/LeadCountByStages';

export default function ChartView({ setChartApi, chartApi }) {
    // const [chartApi, setChartApi] = useState("");
    // let location = useLocation();
    // useEffect(() => {
    //     setChartApi(location.pathname?.split("/")[2]);
    // }, []);
    const [height, setHeight] = useState(400);
    const [width, setWidth] = useState(1000);
    useEffect(() => {

    }, [chartApi]);
    let today = new Date();
    let sixMonthsAgo = new Date(today);
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);


    const [fromDate, setFromDate] = useState(sixMonthsAgo.toISOString().substring(0, 10));
    const [toDate, setToDate] = useState((new Date()).toISOString().substring(0, 10));
    const [isDisabled, setIsDisabled] = useState(true);
    return (
        <Container fluid className='px-5 py-3 bg-white'  >
            <Row className='justify-content-center gap-5 '>
                <Col lg={12}>
                    <Row>
                        <Col lg={11}>
                            {(chartApi !== "lead_count_by_stages" && chartApi !== "day_wise_meeting_chart") &&
                                <Row className=''>
                                    <Col md={2}>
                                        <FormGroup>
                                            <FormLabel>Select Time Period:</FormLabel>
                                            <FormSelect name="date_option"
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    switch (e?.target?.value) {
                                                        case "0":
                                                            setIsDisabled(false);
                                                            break;
                                                        case "3":
                                                            {
                                                                let fromD = new Date();
                                                                fromD.setMonth(fromD.getMonth() - 3);
                                                                setFromDate(fromD.toISOString().substring(0, 10));
                                                            }
                                                            setIsDisabled(true);
                                                            break;
                                                        case "6":
                                                            {
                                                                let fromD = new Date();
                                                                fromD.setMonth(fromD.getMonth() - 6);
                                                                setFromDate(fromD.toISOString().substring(0, 10));
                                                            }
                                                            setIsDisabled(true);
                                                            break;
                                                        case "12":
                                                            {
                                                                let fromD = new Date();
                                                                fromD.setMonth(fromD.getMonth() - 12);
                                                                setFromDate(fromD.toISOString().substring(0, 10));
                                                            }
                                                            setIsDisabled(true);
                                                            break;
                                                    }
                                                }}
                                                className='p-3'
                                            >
                                                <option className='from-control' value={3}>Last 3 Months</option>
                                                <option selected className='from-control' value={6}>Last 6 Months</option>
                                                <option className='from-control' value={12}>Last 1 Year</option>
                                                <option className='from-control' value={0}>Custom Range</option>
                                            </FormSelect>
                                        </FormGroup>
                                    </Col>

                                    <Col md={2}>
                                        <FormGroup>
                                            <FormLabel>From Date</FormLabel>
                                            <FormControl
                                                type='date'
                                                name="from_date"
                                                value={fromDate}
                                                defaultValue={fromDate}
                                                onChange={(e) => {
                                                    (new Date(e.target.value) <= new Date(toDate)) && (setFromDate(e.target.value));
                                                }
                                                }
                                                className='p-3'
                                                disabled={isDisabled}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={2}>
                                        <FormGroup>
                                            <FormLabel>To Date</FormLabel>
                                            <FormControl
                                                type='date'
                                                name="to_date"
                                                value={toDate}
                                                defaultValue={toDate}
                                                onChange={(e) => {
                                                    (new Date(e.target.value) >= new Date(fromDate)) && setToDate(e.target.value);
                                                }}
                                                className='p-3'
                                                disabled={isDisabled}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>}
                        </Col>
                        <Col lg={1}>
                            {/* <Stack direction='horizontal' gap={2}>
                                <Button onClick={() => { setHeight(height + 100); setWidth(width + 100) }}>Add</Button>
                                <Button onClick={() => { setHeight(height - 100); setWidth(width - 100) }}>Less</Button>
                            </Stack> */}
                        </Col>
                    </Row>
                </Col>
                <Col lg={12}>
                    <Row className='justify-content-center'>
                        <Col lg={12}
                         style={{ height: `${height}px`, width: `${width}px`, }}
                         >
                            <Card >
                                <Card.Header style={{ backgroundColor: "#009ab6", borderBottom: "none", color: "#fff", textAlign: "center" }}>{chartApi.split("_").join(" ").toLocaleUpperCase()}</Card.Header>
                                <Card.Body  >
                                    {chartApi === "month_wise_lead_chart" && <MonthWiseBarChart fromDate={fromDate} toDate={toDate} />}
                                    {chartApi === "lead_count_by_agent_chart" && <LeadCountByAgentChart fromDate={fromDate} toDate={toDate} />}
                                    {chartApi === "due_amount_chart" && <DueAmountChart fromDate={fromDate} toDate={toDate} fullMode={true} />}
                                    {chartApi === "business_chart" && <BusinessChart fromDate={fromDate} toDate={toDate} fullMode={true} />}
                                    {chartApi === "task_activity_chart" && <TaskActivityChart fromDate={fromDate} toDate={toDate} />}
                                    {chartApi === "day_wise_meeting_chart" && <DayWiseMeetingChart fromDate={fromDate} toDate={toDate} />}
                                    {/* {chartApi === "lead_count_by_stages" && < fromDate={fromDate} toDate={toDate} />} */}
                                    {chartApi === "product_wise_sales_chart" && <ProductWiseSalesChart fromDate={fromDate} toDate={toDate} />}
                                    {chartApi === "lead_count_by_stages" && <LeadCountByStages />}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>


        </Container>
    );
}
