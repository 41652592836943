import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import SparkApi from '../../api/SparkApi';
import { Button } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MonthWiseBarChart = ({ toDate, fromDate, setChartApi }) => {
  const [reportData, setReportData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([{}]);
  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: false, text: 'Monthly Lead Report' },
    },
  });

  useEffect(() => {
    /*  const fetchLeadReports = async () => {
       const fetchLeadReports = await SparkApi.fetchLeadReports('month_wise_lead_report');
       const queryResult = await SparkApi.fetchQueryData(fetchLeadReports[0].query);
       setReportData(queryResult || []);
     };
     fetchLeadReports(); */
    const fetchChartByFromDate = async () => {
      const result = await SparkApi.fetchChartData({
        apiName: 'month_wise_lead_chart',
        filterColumn: null,
        date: null,
        isMonthWiseFilter: false
      });
      setReportData(result || []);
    };
    fetchChartByFromDate();
  }, []);

  useEffect(() => {
    const fetchChartByFromDate = async () => {
      const result = await SparkApi.fetchChartData({
        apiName: 'month_wise_lead_chart',
        filterColumn: null,
        date: null,
        isMonthWiseFilter: false,
        fromDate,
        toDate
      });
      setReportData(result || []);
    };
    if (toDate && fromDate) fetchChartByFromDate();
  }, [toDate, fromDate]);

  useEffect(() => {
    const monthData = {};
    const convertedData = {};

    reportData.forEach(item => {
      const month = item.createdmonth.trim();
      if (!monthData[month]) {
        monthData[month] = 0;
        convertedData[month] = 0;
      }
      monthData[month] += parseInt(item.totalnew, 10);
      if (item.leadstatus === 'Closed - Converted') {
        convertedData[month] += parseInt(item.totalnew, 10);
      }
    });

    const labels = Object.keys(monthData);
    const leads = Object.values(monthData);
    const convertedLeads = Object.values(convertedData);

    setLabels(labels);
    setDatasets([
      { label: 'Leads', data: leads, backgroundColor: 'rgba(255, 215, 0,1)' },
      { label: 'Converted', data: convertedLeads, backgroundColor: 'rgba(25, 135, 84, 1)' },
    ]);
  }, [reportData]);

  return (<>
    {Array.isArray(reportData) && reportData.length != 0 ?
      <>
        {setChartApi && <Button className='float-end' onClick={() => (setChartApi("month_wise_lead_chart"))}>Full View</Button>}
        <Bar options={options} data={{ labels, datasets }} width={150} height={100} />
      </>
      :

      <div style={{ minHeight: "54vh" }} className='d-flex align-items-center justify-content-center'>
        <div>
          <h1> No data</h1>
        </div>
      </div>

    }
  </>);
}

export default MonthWiseBarChart;
