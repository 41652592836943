import React from "react";

const ReportPDF = (props) => {
    let body = props.data;
    let header = props.header;

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <table>
                <tr>
                    <td style={{ border: "None", textAlign: "center" }}>
                        <h3>
                            Report Name :   {props.reportname}
                        </h3>
                        <hr
                            style={{
                                height: "1px",
                                border: "none",
                                color: "#333",
                                backgroundColor: "#333",
                            }}
                        ></hr>
                    </td>
                </tr>
            </table>
            <br />
            <br />
            <table>
                <thead>
                    <tr>
                        {header?.map((item, index) => (
                            <th key={index}>
                                {item.title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {body?.map((item, index) => (
                        <tr key={index}>
                            {header?.map((data, index) => (
                                <td key={index}>{item[data.prop]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
export default ReportPDF;