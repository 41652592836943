import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import * as constants from '../../constants/CONSTANT';
import jwt_decode from "jwt-decode";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';

import { useNavigate } from "react-router-dom";

const Sidebar = () => {


  const [sidebar, setSidebar] = useState(true);
  const [permissions, setPermissions] = useState();
  const [userInfo, setUserInfo] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    try {
      if (localStorage.getItem('token')) {
        let user = jwt_decode(localStorage.getItem('token'));
        setUserInfo(user);

        var perm = user.permissions.map(function (obj) {
          return obj.name;
        }).join(';')
        setPermissions(perm);
      }
    } catch (error) {
      //console.log(error)
    }

  }, []);

  const navigatePage = (destination, defaultHide) => {
    if (isMobile || defaultHide)
      hideSideBar();
    setTimeout(() => {
      navigate(destination);
    }, 100)


  }
  const hideSideBar = () => {
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
  }

  return (
    <>
      <nav id="sidebar" className={isMobile ? 'hide' : 'show'} style={{ fontSize: "small", backgroundImage: `url(${userInfo.sidebarbgurl})` }}>
        <div>
          <div className="sidebar-header" >
            {userInfo.logourl ? <img src="logo.png" style={{ height: "60px" }} /> : ''}
          </div>

          <ul className="list-unstyled components">
            <li className={`${location.pathname === '/' ? 'active' : ''}`}>

              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/", false)} style={{ borderTop: "1px solid #fff" }}><i className="fa-solid fa-house mx-2" ></i> Home</span>
            </li>
            <li >
              {/* <Link to="/"> <i className="fa-solid fa-chart-simple mx-2"></i> Accounts</Link> */}
            </li>
            {permissions && (permissions.indexOf(constants.VIEW_ACCOUNT) >= 0 || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/accounts') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/accounts", false)}><i className="fa-solid fa-building mx-2"></i> Accounts</span>
              </li>
              : ''}

            {/* {permissions && (permissions.indexOf(constants.VIEW_PRODUCT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/products"> <i className="fa-solid fa-chart-simple mx-2"> </i> Products</Link>
                </li>
                : ''} */}
            {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/contacts') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/contacts", false)}> <i className="fa-solid fa-address-book mx-2"></i> Contacts</span>
              </li> : ''}

            {permissions && (permissions.indexOf(constants.VIEW_BUSINESS) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/business') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/business", false)}> <i class="fa-solid fa-coins mx-2"></i> Business</span>
              </li> : ''}

            {permissions && (permissions.indexOf(constants.VIEW_LEAD) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/leads') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/leads", true)}> <i className="fa-solid fa-bolt mx-2"></i> Leads</span>
              </li> : ''}

            {permissions && (permissions.indexOf(constants.VIEW_PRODUCT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/products') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/products", false)}> <i className="fa fa-solid fa-store mx-2"></i> Products</span>
              </li> : ''}

            {/* {permissions && (permissions.indexOf(constants.VIEW_INCIDENT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/incidents') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/incidents", false)}> <i className="fa fa-solid fa-headset mx-2"></i> Incidents</span>
              </li> : ''} */}

            {/* {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/products"><i className="fa-solid fa-user-md mx-2"></i> Test Master</Link>
                </li>: ''}
                {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/medicaltests"><i className="fa-solid fa-hospital mx-2"></i> Medical Test</Link>
                </li>: ''} */}

            {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/meetings') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/meetings", false)}> <i className="fa-solid fa-calendar-days mx-2"> </i> Meetings</span>
              </li> : ''}

            {!isMobile && permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/users') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/users", false)}> <i class="fa-solid fa-user mx-2"></i>  Users</span>
              </li> : ''}


            {/* {
            // userInfo.userrole && (userInfo.userrole.indexOf('USER') >= 0)
            true
             ? */}
              <li className={`${location.pathname.includes('/usertracking') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/usertracking", false)}><i class="fa-solid fa-user mx-2"></i>  Check In / Out</span>
              </li> 
              {/* : ''} */}

            {/* {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0 
            {permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/users') ? 'active' : ''}`}>
                <Link to="/users"> <i class="fa-solid fa-user mx-2"></i>  Users</Link>
              </li> : ''}
            {/* {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/commissions"><i className="fa-solid fa-percent mx-2"></i>Commission Master</Link>
                </li>: ''}

                {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0 
                                                      || permissions.indexOf(constants.MODIFY_ALL) >= 0 )?
                <li>
                <Link to="/packages"><i className="fa-solid fa-fort-awesome mx-2"></i>Package</Link>
                </li>: ''} */}


            {!isMobile && permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) && userInfo.userrole && (userInfo.userrole!='USER')  ?
              <li className={`${location.pathname.includes('/reports') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/reports", true)}><i className="fa-solid fa-chart-simple mx-2"> </i> Reports</span>
              </li> : ''}

              {/* {!isMobile && permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) && userInfo.userrole && (userInfo.userrole!='USER')  ? */}
              <li className={`${location.pathname.includes('/product_report') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/product_report", true)}><i className="fa-solid fa fa-pie-chart mx-2"> </i>Product Report</span>
              </li> 
              {/* : ''} */}

            {/* <li className={`${location.pathname.includes('/dashboard') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/dashboards", true)}><i className="fa-solid fa-gauge mx-2"> </i> Dashboard</span>
            </li> */}

            {!isMobile && permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/attandancereport') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/attandancereport", false)}> <i class="fa-solid fa-database mx-2"></i>Attendance Report</span>
              </li> : ''}

            {!isMobile && permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/backup') ? 'active' : ''}`}>
                <span className="custom-link-sidebar" onClick={(e) => navigatePage("/backup", false)}> <i class="fa-solid fa-database mx-2"></i> Backup</span>
              </li> : ''}

            <li className={`${location.pathname.includes('/myprofile') ? 'active' : ''}`}>
              <span className="custom-link-sidebar" onClick={(e) => navigatePage("/myprofile", false)}> <i className="fa fa-user-circle mx-2"></i>My Profile</span>
            </li>

            {/* {permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
              || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
              <li className={`${location.pathname.includes('/changepassword') ? 'active' : ''}`}>
                <Link to="/changepassword"><i className="fa-solid fa-key mx-2"></i>Change Password</Link>
              </li> : ''} */}



          </ul>
          <div className="sidebar-header" style={{ padding: "2rem 2rem 2rem 1rem", borderTop: "1px solid #fff", textAlign: "center" }}>
            <img src="/sidebar_logo.png" style={{ width: "80%" }} />
            <p style={{ paddingTop: ".5rem" }}>Powered by <a href="https://indicrm.io" style={{ color: "#fff" }}>indiCRM.io</a></p>

          </div>
        </div>
      </nav>
    </>
  )
}

export default Sidebar
