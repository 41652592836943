import React, { useEffect, useState } from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
import SparkApi from '../../api/SparkApi';
import { backgroundColors } from '../common/ChartColor';
import { Button } from 'react-bootstrap';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
// console.log(CanvasJSReact)
// CanvasJSReact.CanvasJS.addColorSet("colorSets",
//     ["#4CB140","#F0AB00","rgba(255, 215, 0, .8)", "rgba(0, 128, 128,.8)", "rgba(255, 0, 0, .8)", "rgba(100, 216, 255, .8)", "rgba(255, 179, 72, .8)"]);
// console.log(CanvasJSChart)
// CanvasJSChart.addColorSet("colorSets",
//    backgroundColors);
export default function LeadCountByStages({ setChartApi }) {
    const [statusDatas, setStatusDatas] = useState();
    const [options, setOptions] = useState({ data: [] });
    useEffect(() => {

        const init = async () => {
            const fetchLeadsStatus = await SparkApi.fetchLeadsStatusWithCount();
            //console.log('fetchLeadsStatus',fetchLeadsStatus)
            if (fetchLeadsStatus && fetchLeadsStatus?.length) {
                let data = {};
                for (let item of fetchLeadsStatus) {
                    //setStatusDatas(...statusDatas ,Prospects);
                    if (item?.status === 'Prospect') {
                        data.Prospects = item?.status === 'Prospect' ? item.count : 0
                    } else if (item?.status === 'Drawing Received') {
                        data.DrawingReceived = item?.status === 'Drawing Received' ? item.count : 0
                    } else if (item?.status === 'Quotation Sent') {
                        data.QuotationSent = item?.status === 'Quotation Sent' ? item.count : 0
                    } else if (item?.status === 'Discussions') {
                        data.Discussions = item?.status === 'Discussions' ? item.count : 0
                    } else if (item?.status === 'Order Received') {
                        data.OrderReceived = item?.status === 'Order Received' ? item.count : 0
                    } else if (item?.status === 'Transfer to Installation & Accounts') {
                        data.Transfer = item?.status === 'Transfer to Installation & Accounts' ? item.count : 0
                    } else if (item?.status === 'Closed - Converted') {
                        data.Converted = item?.status === 'Closed - Converted' ? item.count : 0
                    }
                }
                console.log('data', data)
                setStatusDatas(data);
            }
        }
        init();
    }, []);

    useEffect(() => {
        console.log("statusDatas", statusDatas)
        setOptions({
            animationEnabled: true,
            colorSet: "colorSets",
            theme: "light1",

            data: [{
                // neckWidth: 100,
                // neckHeight: 200,
                // valueRepresents: "area",
                type: "funnel",
                toolTipContent: "<b>{label}</b>:  <b>({percentage})</b>",
                indexLabelPlacement: "inside",
                indexLabel: "{label} ({percentage})",
                dataPoints: [
                    { y: 1400, label: "Prospects", percentage: statusDatas && statusDatas?.Prospects ? statusDatas?.Prospects : 0 },
                    { y: 1300, label: "Drawing Received", percentage: statusDatas && statusDatas?.DrawingReceived ? statusDatas?.DrawingReceived : 0 },
                    { y: 1200, label: "Quotation Sent", percentage: statusDatas && statusDatas?.QuotationSent ? statusDatas?.QuotationSent : 0 },
                    { y: 1100, label: "Discussions", percentage: statusDatas && statusDatas?.Discussions ? statusDatas?.Discussions : 0 },
                    { y: 1080, label: "Order Received", percentage: statusDatas && statusDatas?.OrderReceived ? statusDatas?.OrderReceived : 0 },
                    { y: 865, label: "Transfer to Installation & Accounts", percentage: statusDatas && statusDatas?.Transfer ? statusDatas?.Transfer : 0 },
                    { y: 778, label: "Closed - Converted", percentage: statusDatas && statusDatas?.Converted ? statusDatas?.Converted : 0 }
                ]
            }]
        });
        //calculate percentage
        // let dataPoint = options.data[0].dataPoints;
        // let total = dataPoint[0].y;
    }, [statusDatas]);
    useEffect(() => {

    })
    return (
        <>
            {statusDatas ?
                <>
                    {setChartApi && <div className='d-flex justify-content-end'>
                        <Button onClick={() => (setChartApi("lead_count_by_stages"))}>Full View</Button>
                    </div>}
                    <CanvasJSChart options={options} />
                </>
                :
                <div style={{ minHeight: "60vh" }} className='d-flex align-items-center justify-content-center'>
                    <div>
                        <h1> No data</h1>
                    </div>
                </div>
            }

        </>
    )
}
