import React, { useState, useEffect } from 'react'

import { Badge, Col, Container, FormControl, FormGroup, FormLabel, FormSelect, Modal, Row, Stack } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import BarChart from './charts/BarChart';
import PieChart from './charts/PieChart';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import EventEdit from './EventEdit'
import GroupBarChart from './charts/GroupBarChart';
import Main from "../components/layout/Main";
import MonthWiseBarChart from './charts/MonthWiseBarChart';
import CanvasJSReact from '@canvasjs/react-charts';
import jwt_decode from "jwt-decode";
import MonthWiseLeadChart from './charts/MonthWiseLeadChart';
import LeadCountByAgentChart from './charts/LeadCountByAgentChart';
import DueAmountChart from './charts/DueAmountChart';
import BusinessChart from './charts/BusinessChart';
import TaskActivityChart from './charts/TaskActivityChart';
import DayWiseMeetingChart from './charts/DayWiseMeetingChart';
import ProductWiseSalesChart from './charts/ProductWiseSalesChart';
import ChartView from './ChartView';
import LeadCountByStages from './charts/LeadCountByStages';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Home = () => {


  const [newLead, setNewLad] = useState("");
  const [allContacts, setAllCOntacts] = useState("");
  const [totalBusiness, setTotalBusiness] = useState("");
  const [showEventModel, setShowEventModel] = useState(false);
  const calendarRef = React.createRef();
  const [reletedListTask, setReletedListTask] = useState(false);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [workingLeads, setWorkingLeads] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();
  // const [studentCountArr, setStudentCountArr] = useState([]);
  // const [staffCountArr, setstaffCountArrr] = useState([]);
  // const [branchArr, setBranchArr] = useState([]);
  // const [monthName, setMonthName] = useState([]);
  // const [monthCount, setMonthCount] = useState([]);
  // const [monthdata, setmonthdata] = useState([]);
  const [chartApi, setChartApi] = useState("");

  const [statusDatas, setStatusDatas] = useState();

  let today = new Date();
  let sixMonthsAgo = new Date(today);
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);


  const [fromDate, setFromDate] = useState(sixMonthsAgo.toISOString().substring(0, 10));
  const [toDate, setToDate] = useState((new Date()).toISOString().substring(0, 10));
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    //const st = "Open - Not Contactecd"
    async function init() {
      const fetchNewLeads = await SparkApi.fetchNewLeads();
      const fetchWorkingLeads = await SparkApi.fetchWorkingLeads();
      const fetchallcontacts = await SparkApi.fetchallcontacts();
      const fetchTotalBusiness = await SparkApi.fetchTotalBusiness();
      const leadCountByStatus = await SparkApi.runReportByName('lead_count_by_stages');
      const fetchLeadsStatus = await SparkApi.fetchLeadsStatusWithCount();
      //console.log('fetchLeadsStatus',fetchLeadsStatus)
      if (fetchLeadsStatus && fetchLeadsStatus?.length) {
        let data = {};
        for (let item of fetchLeadsStatus) {
          //setStatusDatas(...statusDatas ,Prospects);
          if (item?.status === 'Prospect') {
            data.Prospects = item?.status === 'Prospect' ? item.count : 0
          } else if (item?.status === 'Drawing Received') {
            data.DrawingReceived = item?.status === 'Drawing Received' ? item.count : 0
          } else if (item?.status === 'Quotation Sent') {
            data.QuotationSent = item?.status === 'Quotation Sent' ? item.count : 0
          } else if (item?.status === 'Discussions') {
            data.Discussions = item?.status === 'Discussions' ? item.count : 0
          } else if (item?.status === 'Order Received') {
            data.OrderReceived = item?.status === 'Order Received' ? item.count : 0
          } else if (item?.status === 'Transfer to Installation & Accounts') {
            data.Transfer = item?.status === 'Transfer to Installation & Accounts' ? item.count : 0
          } else if (item?.status === 'Closed - Converted') {
            data.Converted = item?.status === 'Closed - Converted' ? item.count : 0
          }
        }
        console.log('data', data)
        setStatusDatas(data);
      }

      const allMeetings = await SparkApi.fetchAllMeetings('today');
      //console.log('leadCountByStatus' , leadCountByStatus)
      setTodayMeetings(allMeetings ? allMeetings : []);
      //console.log('fetchTotalBusiness:', fetchTotalBusiness);
      setNewLad(fetchNewLeads?.total);
      setAllCOntacts(fetchallcontacts?.total);
      setTotalBusiness(fetchTotalBusiness?.total)
      ////console.log("fetchNewLeads" , fetchNewLeads);
      ////console.log("fetchLeads=>" + JSON.stringify(fetchNewLeads));
      ////console.log("fetchallcontacts=>" + JSON.stringify(fetchallcontacts));
      ////console.log("fetchallActiveusers=>" + JSON.stringify(fetchallActiveusers));
      //console.log('lead counts', leadCountByStatus);
      let allLeadStatus = JSON.parse(localStorage.getItem("lead_status"));
      console.log('allLeadStatus', allLeadStatus)
      let countWorking = 0;
      leadCountByStatus.forEach(function (item) {

        allLeadStatus.forEach(function (val) {
          if (val.is_converted === false && val.is_lost === false && val.label === item.leadstatus) {
            countWorking += parseInt(item.count);
          }
        })
      })


      setWorkingLeads(fetchWorkingLeads?.total);


    }
    let user = jwt_decode(localStorage.getItem('token'));
    setUserInfo(user);

    init();
  }, []);
  // console.log('statusDatas', statusDatas)

  var dataPoint;
  var total;
  const options = {
    animationEnabled: true,
    /* title: {
      text: "Sales Analysis"
    }, */
    data: [{
      type: "funnel",
      toolTipContent: "<b>{label}</b>:  <b>({percentage})</b>",
      indexLabelPlacement: "inside",
      indexLabel: "{label} ({percentage})",
      dataPoints: [
        { y: 1400, label: "Prospects", percentage: statusDatas && statusDatas?.Prospects ? statusDatas?.Prospects : 0 },
        { y: 1300, label: "Drawing Received", percentage: statusDatas && statusDatas?.DrawingReceived ? statusDatas?.DrawingReceived : 0 },
        { y: 1200, label: "Quotation Sent", percentage: statusDatas && statusDatas?.QuotationSent ? statusDatas?.QuotationSent : 0 },
        { y: 1100, label: "Discussions", percentage: statusDatas && statusDatas?.Discussions ? statusDatas?.Discussions : 0 },
        { y: 1080, label: "Order Received", percentage: statusDatas && statusDatas?.OrderReceived ? statusDatas?.OrderReceived : 0 },
        { y: 865, label: "Transfer to Installation & Accounts", percentage: statusDatas && statusDatas?.Transfer ? statusDatas?.Transfer : 0 },
        { y: 778, label: "Closed - Converted", percentage: statusDatas && statusDatas?.Converted ? statusDatas?.Converted : 0 }
      ]
    }]
  }
  //calculate percentage
  dataPoint = options.data[0].dataPoints;
  total = dataPoint[0].y;
  // for (var i = 0; i < dataPoint.length; i++) {
  //   if (i == 0) {
  //     options.data[0].dataPoints[i].percentage = 100;
  //   } else {
  //     options.data[0].dataPoints[i].percentage = ((dataPoint[i].y / total) * 100).toFixed(2);
  //   }
  // }



  const NavigateToChart = (e) => {
    console.log(e, e.target.parentElement)
    setChartApi(e.target.parentElement.id)
    // navigate(`chart/${e.target.parentElement.id}`);
    // window.open(`${window.location.href}chart/${e.target.parentElement.id}`);
  }



  const createLead = () => {
    navigate(`/leads/e`);
  };


  const submitEvents = async (eventRec) => {
    ////console.log('eventRec home submit', eventRec)
    setShowEventModel(false);
  }



  return (

    <Container>

      <Row className='p-2 mt-4 mb-4 '>
        <Col lg={3}>
          <Link to="/leads" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center  " style={{ backgroundColor: 'white', borderLeft: '4px solid #f7981c' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#f7981c' }}></i>
                <i class="fa-solid fa-bolt fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">NEW LEADS</h6>
                <h1 className='mb-0 d-inline '>{newLead}</h1><Badge bg="light" text="dark">Running</Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/leads" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center " style={{ backgroundColor: 'white', borderLeft: '4px solid #FFD700' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#FFD700' }}></i>
                <i class="fa-solid fa-coins fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">WORKING LEADS</h6>
                <h1 className='mb-0 d-inline '>{workingLeads}</h1> <Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/contacts" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center " style={{ backgroundColor: 'white', borderLeft: '4px solid #198754' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#198754' }}></i>
                <i class="fa-solid fa-user-tie fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">CUSTOMERS</h6>
                <h1 className='mb-0 d-inline '>{allContacts}</h1><Badge bg="light" text="dark">Total</Badge>
              </div>
            </div>
          </Link>
        </Col>
        <Col lg={3}>
          <Link to="/business" className='text-decoration-none text-reset'>
            <div className="p-3 d-flex align-items-center" style={{ backgroundColor: 'white', borderLeft: '4px solid #4798b5' }}>
              {/* <i className="flex-shrink-0 me-3 fa-solid fa-building fa-3x circle-icon" style={{color: 'green'}}></i> */}

              <span class="fa-stack fa-2x">
                <i class="fa-solid fa-circle fa-stack-2x" style={{ color: '#4798b5' }}></i>
                <i class="fa-solid fa-arrow-trend-up fa-stack-1x" style={{ color: 'white', fontSize: '2rem' }}></i>
              </span>
              <div className="flex-grow-1">
                <h6 className="text-muted mb-1">REVENUE</h6>
                <h1 className='mb-0 d-inline '>{parseFloat(totalBusiness / 100000).toFixed(2)}</h1><Badge bg="light" text="dark"> ₹ Lakh</Badge>
              </div>
            </div>
          </Link>
        </Col>

      </Row>

      <Row className='mx-2 bg-white py-3'>
        <Col md={2}>
          <FormGroup>
            <FormLabel>Select Time Period:</FormLabel>
            <FormSelect name="date_option"
              onChange={(e) => {
                console.log(e.target.value);
                switch (e?.target?.value) {
                  case "0":
                    setIsDisabled(false);
                    break;
                  case "3":
                    {
                      let fromD = new Date();
                      fromD.setMonth(fromD.getMonth() - 3);
                      setFromDate(fromD.toISOString().substring(0, 10));
                    }
                    setIsDisabled(true);
                    break;
                  case "6":
                    {
                      let fromD = new Date();
                      fromD.setMonth(fromD.getMonth() - 6);
                      setFromDate(fromD.toISOString().substring(0, 10));
                    }
                    setIsDisabled(true);
                    break;
                  case "12":
                    {
                      let fromD = new Date();
                      fromD.setMonth(fromD.getMonth() - 12);
                      setFromDate(fromD.toISOString().substring(0, 10));
                    }
                    setIsDisabled(true);
                    break;
                }
              }}
              className='p-3'
            >
              <option className='from-control' value={3}>Last 3 Months</option>
              <option selected className='from-control' value={6}>Last 6 Months</option>
              <option className='from-control' value={12}>Last 1 Year</option>
              <option className='from-control' value={0}>Custom Range</option>
            </FormSelect>
          </FormGroup>
        </Col>

        <Col md={2}>
          <FormGroup>
            <FormLabel>From Date</FormLabel>
            <FormControl
              type='date'
              name="from_date"
              value={fromDate}
              defaultValue={fromDate}
              onChange={(e) => {
                (new Date(e.target.value) <= new Date(toDate)) && (setFromDate(e.target.value));
              }
              }
              className='p-3'
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>

        <Col md={2}>
          <FormGroup>
            <FormLabel>To Date</FormLabel>
            <FormControl
              type='date'
              name="to_date"
              value={toDate}
              defaultValue={toDate}
              onChange={(e) => {
                (new Date(e.target.value) >= new Date(fromDate)) && setToDate(e.target.value);
              }}
              className='p-3'
              disabled={isDisabled}
            />
          </FormGroup>
        </Col>

      </Row>

      <Row className=''>
        {/* <Col md={12} className='bg-white text-dark p-3'> */}

        {/* </Col> */}
        <Col lg={6} className="text-center p-3">
          <Card className=""  >
            <Card.Header >MONTH WISE LEADS PROGRESS</Card.Header>
            <Card.Body id="month_wise_lead_chart">

              {/* <div style={{ height: '350px' }}> */}
              <MonthWiseBarChart fromDate={fromDate} toDate={toDate} setChartApi={setChartApi}/>
              {/* </div> */}
            </Card.Body>
          </Card>
        </Col>

        {/*  <Col lg={6} className="text-center">
          <Card className="" >
            <Card.Header >STAFF LEADS PROGRESS</Card.Header>
            <Card.Body>
              {/* <Card.Text> */}
        {/* <div style={{ height: '100%' }}>
                  <GroupBarChart />
                </div> */}
        {/* </Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
        {/* 
        <Col lg={6} className="text-center p-3">
          <Card className="" >
            <Card.Header >MONTH WISE LEAD </Card.Header>
            <Card.Body>
              
              <MonthWiseLeadChart />
             
            </Card.Body>
          </Card>
        </Col> */}

        <Col lg={6} className="text-center p-3">
          <Card className="" >
            <Card.Header >LEAD COUNT BY AGENT</Card.Header>
            <Card.Body id="lead_count_by_agent_chart">

              <LeadCountByAgentChart fromDate={fromDate} toDate={toDate} setChartApi={setChartApi}/>

            </Card.Body>
          </Card>
        </Col>

        <Col lg={6} className="text-center p-3">
          <Card className="" >
            <Card.Header >DAY WISE MEETING STATUS</Card.Header>
            <Card.Body id="day_wise_meeting_chart">

              <DayWiseMeetingChart setChartApi={setChartApi} />

            </Card.Body>
          </Card>
        </Col>

        <Col lg={6} className="text-center p-3">
          <Card className="" >
            <Card.Header >BUSINESS STATUS</Card.Header>
            <Card.Body id="business_chart">

              <BusinessChart fromDate={fromDate} toDate={toDate} setChartApi={setChartApi} />

            </Card.Body>
          </Card>
        </Col>

        <Col lg={6} className="text-center p-3">
          <Card className="" >
            <Card.Header >PRODUCT WISE SALES PROGRESS</Card.Header>
            <Card.Body id="product_wise_sales_chart">

              <ProductWiseSalesChart fromDate={fromDate} toDate={toDate} setChartApi={setChartApi}/>

            </Card.Body>
          </Card>
        </Col>

        {/*  {userInfo.userrole && (userInfo.userrole === 'SUPER_ADMIN' ||
          userInfo.userrole === 'ADMIN') &&  */}
        <Col lg={6} className="mt-3">
          <div >

            <Card className="" >
              <Card.Header style={{ backgroundColor: "#009ab6", borderBottom: "none", color: "#fff", textAlign: "center" }}>LEAD COUNT BY STAGES</Card.Header>
              <Card.Body id="lead_count_by_stages">
                
                <LeadCountByStages setChartApi={setChartApi} />
                {/* <div  id="lead_count_by_stages">
                  <CanvasJSChart options={options}

                  />
                </div> */}
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}

              </Card.Body>
            </Card>
          </div>
        </Col>

        <Col lg={12}>
          <Row>
            <Col md={6} className="text-center p-3">
              <Card >
                <Card.Header >TASK STATUS</Card.Header>
                <Card.Body className='' id="task_activity_chart">

                  <TaskActivityChart fromDate={fromDate} toDate={toDate} setChartApi={setChartApi}/>

                </Card.Body>
              </Card>
            </Col>

            <Col md={6} className=" text-center p-3">
              <Card className="" >
                <Card.Header >DUE AMOUNT STATUS</Card.Header>
                <Card.Body className='' id="due_amount_chart">

                  <DueAmountChart fromDate={fromDate} toDate={toDate} setChartApi={setChartApi}/>

                </Card.Body>
              </Card>
            </Col>

          </Row>
        </Col>




        <Col lg={6} className="mt-3">

          <div style={{ height: '350px' }}>

            <Card className="">
              <Card.Header style={{ backgroundColor: "#009ab6", borderBottom: "none", color: "#fff", textAlign: "center" }}>QUICK ACTIONS</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Text>
                      <div width>
                        <Button className=" btn-primary btn-sm my-2 btn-block" style={{ width: '80%' }} onClick={() => createLead(true)}> <i class="fa-solid fa-bolt"></i> NEW LEAD</Button>

                      </div>
                      <div>
                        <Button className=" btn-custom btn-sm btn-block" style={{ width: '80%' }} onClick={() => setShowEventModel(true)}> <i class="fa-solid fa-list-check" ></i> NEW METINGS</Button>

                      </div>
                    </Card.Text>
                  </Col>
                  <Col>
                    <center>
                      <div ><i class="fa-regular fa-calendar-days fa-2x" style={{ color: "#E73F59" }}></i></div>
                      <p className='pt-2'><i>You have <Link to={"/meetings/today"}>
                        <b>{todayMeetings?.length}</b>
                      </Link> meetings today</i></p>
                    </center>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-muted">Followup with <a href="/leads" style={{ textDecoration: "none" }}>these</a> leads today</Card.Footer>
            </Card>
          </div>
        </Col>

      </Row>
      {showEventModel && (
        <EventEdit
          show={showEventModel}
          onHide={() => setShowEventModel(false)}
          parentid="abc"
          //eventRec={event}
          table="user"
          submitEvents={submitEvents}
        />
      )}
      <>
        {/* <Button variant="primary" onClick={() => setChartModalShow(true)}>
        Custom Width Modal
      </Button> */}

        <Modal
          show={chartApi ? true : false}
          onHide={() => setChartApi("")}
          dialogClassName="modal-90w"
          aria-labelledby="ChartModal"
          fullscreen={true}
        >
          <Modal.Header closeButton>
            <Modal.Title id="ChartModal" className=''>
              {chartApi.split("_").join(" ").toLocaleUpperCase()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <ChartView chartApi={chartApi} setChartApi={setChartApi} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>

    </Container>

  )
}

export default Home