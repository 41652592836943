import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table, ProgressBar, FormGroup, FormLabel, FormSelect, Container, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import jwt_decode from "jwt-decode";
import Modal from 'react-bootstrap/Modal';
import PubSub from 'pubsub-js';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";

const LeadList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [leadname, setLeadName] = useState();
  // const[contact,setContact] = useState(location.state ? location.state : {});
  const [lead, setLead] = useState([]);
  const [usertype, setUsertype] = useState();
  const [leadStatus, setLeadStatus] = useState();
  const [userInfo, setUserInfo] = useState({});
  //////console.log('location.state ='+location.state)
  const [leadStatusArray, setleadStatusArray] = useState(JSON.parse(localStorage.getItem('lead_status')));
  let [filter, setFilter] = useState({});
  const [isCSVFiles, setIsCSVFiles] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const handleClose = () => setIsCSVFiles(false);
  // States of set Assign user for the change owner functionality
  const [changeOwnerModal, setChangeOwnerModal] = useState(false);
  const [fromUser, setFromUser] = useState({});
  const [toUser, setToUser] = useState({});
  const [users, setUsers] = useState([]);
  // for refresh the records after owner change
  const [refreshRecords, setRefreshRecords] = useState(false);
  const [ownerLeads, setOwnerLeads] = useState([]);
  const [ownerLeadsHeader, setOwnerLeadsHeader] = useState([]);

  const tableRef = useRef(null);
  // Use effect for the change owner functionality to load all users(owners)
  useEffect(() => {
    (async () => {
      const fetchUsers = await SparkApi.fetchUsers();
      console.log("fetchUsers",);
      setUsers(fetchUsers.map(({ id, username, usertype }) => ({ value: id, label: `${username} - ${usertype}` })));
    })();
  }, []);

  // handlers for dropdown(select)
  const handelFromUserSelect = (e) => {
    console.log("close", e);
    if (e) {
      setFromUser(e);
    }
    else {
      setFromUser(e);
    }
  }
  const handelToUserSelect = (e) => {
    setToUser(e)
  }
  const handelChangeOwnerModalClose = () => {
    setChangeOwnerModal(false);
    setRefreshRecords(!(refreshRecords));
    setOwnerLeads([]);
    setOwnerLeadsHeader([]);
    setFromUser({});
    setToUser({});
  }
  // change owner functionality
  const ChangeOwner = (e) => {
    e.preventDefault();
    // console.log();
    // console.log("leads", leads);

    // console.log("leads", leads);

    // console.log("leads", leads);
    // console.log("fromUser", fromUser);
    // console.log("toUser", toUser);
    let leads = [...(tableRef?.current?.querySelectorAll(`input[name='leads']`))];
    leads = leads?.filter((elm) => (elm.checked));
    leads = leads?.map((elm) => (elm.id));

    (async () => {
      const result = await SparkApi.ChangeOwner({ fromUser: fromUser.value, toUser: toUser.value, leads });
      // console.log("result Owner",result);
      if (result.success) {
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Change Owner', message: 'Owner changed successfully' });
        handelChangeOwnerModalClose();
      } else {
        PubSub.publish('RECORD_ERROR_TOAST', { title: 'Change Owner Error', message: 'Some error occurred' });
      }
    })();
  }
  const handelCheckBox = (e) => {
    // console.log("check",e.target,tableRef.current.querySelectorAll(`input[name='leads']`))
    // console.log();
    [...(tableRef.current.querySelectorAll(`input[name='leads']`))].forEach((elm) => {
      // console.log("check", elm);
      elm.checked = e.target.checked;
    })
  }

  // Get leads of owner
  useEffect(() => {
    // console.log("leads", fromUser);
    // console.log("leads", fromUser?.value);
    (async () => {
      if (fromUser && fromUser?.value) {

        const leads = await SparkApi.getLeadsByStaffId(fromUser?.value);
        console.log("leads", leads);
        if (leads.success) {

          setOwnerLeads(leads?.data);
          setOwnerLeadsHeader([
            {
              title: <Form.Check // prettier-ignore
                type={"checkbox"}
                label="Select All"
                name="all_leads"
                onChange={handelCheckBox}
              />,
              prop: "id",
              isFilterable: true,
              cell: (row) => (
                // <Link to={"/leads/" + row.id} state={row}>
                //   {row.leadname}
                // </Link>
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                  id={`${row.id}`}
                  name="leads"
                />
              ),
            },
            {
              title: "Name",
              prop: "leadname",
              isFilterable: true,
              cell: (row) => (
                <Link to={"/leads/" + row.id} state={row}>
                  {row.leadname}
                </Link>
              ),
            },
            {
              title: "Lead Status",
              prop: "leadstatus",
              isFilterable: true, isSortable: true,
              cell: (row) => {
                return (
                  <Badge bg={getStatusClass(row.leadstatus)} style={{ display: "block", paddingBottom: "5px" }}>
                    {row.leadstatus}
                  </Badge>
                );

              },
            },
            {
              title: "Created Date", prop: "createddate", isSortable: true, isFilterable: true, cell: (row) => (
                <span>
                  {moment(row.createddate).format('DD-MM-yyyy')}
                </span>
              )
            },
            {
              title: "Assigned",
              prop: "ownername",
              isFilterable: true,
              isSortable: true,
              cell: (row) => {
                const titleAbbreviations = {
                  "Sales Admin": "S. A.",
                  "Installation Admin": "I. A.",
                  "Accounts Admin": "A. A.",
                  "Installation Engineer": "I. E.",
                  "Business Development Executive": "B. D. E.",
                  "Customer Relationship Executive": "C. R. E.",
                  "Office Coordinator": "O. C.",
                  "Office Executive": "O. E.",
                  "Super_Admin": "Super_Admin" // If no abbreviation is needed
                };


                // Split ownername and ownerid arrays
                const ownerNames = row.ownername ? row.ownername.split(', ') : [];
                const ownerIds = row.ownerid ? row.ownerid : [];

                // Function to shorten titles
                const shortenTitle = (name) => {
                  const [fullName, title] = name.split(' - ');
                  const shortTitle = titleAbbreviations[title.trim()] || title; // Use abbreviation if available
                  return `${fullName} - ${shortTitle}`;
                };

                // Ensure ownerNames and ownerIds arrays are of the same length
                const links = ownerNames.map((name, index) => (
                  <Link
                    key={index} // Use index as key if ownerIds[index] is not reliable
                    to={`/users/${ownerIds[index]}`}
                    state={row}
                    className="align-items-center justify-content-start"
                  >
                    {shortenTitle(name)}<br />
                  </Link>
                ));
                return <span>{links}</span>;
              },
            },
          ]);
        }
        else {
          setOwnerLeads([]);
          setOwnerLeadsHeader([]);
        }
      } else {
        setOwnerLeads([]);
        setOwnerLeadsHeader([]);
      }
    })();
  }, [fromUser])


  useEffect(() => {
    console.log('sdf', jwt_decode(localStorage.getItem('token')))
    setUserInfo(jwt_decode(localStorage.getItem('token')));
    getLeadsList();
  }, [leadname, refreshRecords]);


  const getLeadsList = () => {
    async function init() {
      const leads = await SparkApi.fetchLead();

      if (leads) {
        // Sort leads by createddate in descending order
        const sortedLeads = leads.sort((a, b) => new Date(b.createddate) - new Date(a.createddate));

        console.log("Sorted lead data =>", sortedLeads);
        setBody(sortedLeads);
        setLead(sortedLeads);

        // Assuming you want to set the lead name of the first lead after sorting
        if (sortedLeads.length > 0) {
          setLeadName(`${sortedLeads[0].firstname} ${sortedLeads[0].lastname}`);
        }
      } else {
        setBody([]);
        setLead([]);
      }
    }

    init();
  };


  useEffect(() => {
    if (filter) {
      doFilterData();
    }
  }, [filter]);

  const onFilterSelection = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  }

  const doFilterData = (event) => {

    let tempData = lead.filter((item) => {
      if (!filter.leadstatus || filter.leadstatus === '' || item.leadstatus === filter.leadstatus) {
        return item;
      }
    });

    tempData = tempData.filter((item) => {
      if (!filter.area || filter.area === '' || item.area === filter.area) {
        return item;
      }
    });

    tempData = tempData.filter((item) => {
      if (!filter.user || filter.user === '' || item?.ownerid?.includes(filter.user)) {
        return item;
      }
    });

    setBody(tempData);
  }

  const getStatusClass = (status) => {
    ////console.log('status',status)
    let accessStatusRec = leadStatusArray.filter((value, index, array) => {
      if (value.label === status) {

        return true
      }

    });
    ////console.log('accessStatusRec',accessStatusRec)
    if (accessStatusRec && accessStatusRec.length > 0) {

      if (accessStatusRec[0].is_converted === true) {
        ////console.log('if isconverted')

        return 'success';
      } else if (accessStatusRec[0].is_lost === true) {
        return 'secondary';
      } else {
        return 'primary';
      }
    } else {
      return 'secondary';
    }
  }


  // Create table headers consisting of 4 columns.
  const header = [];

  if (!isMobile) {
    header.push(
      {
        title: "Name",
        prop: "leadname",
        isFilterable: true,
        cell: (row) => (
          <Link to={"/leads/" + row.id} state={row}>
            {row.leadname}
          </Link>
        ),
      },
      { title: "Phone", prop: "phone", isFilterable: true },
      /* { title: "Email", prop: "email", isFilterable: true }, */

      {
        title: "Lead Status",
        prop: "leadstatus",
        isFilterable: true,
        isSortable: true,
        cell: (row) => {
          return (
            <Badge bg={getStatusClass(row.leadstatus)} style={{ display: "block", paddingBottom: "5px" }}>
              {row.leadstatus}
            </Badge>
          );

        },
      },
      /*  { title: "Company", prop: "company", isFilterable: true, isSortable: true }, */
      { title: "Lead Source", prop: "leadsource", isFilterable: true },
      { title: "Other Source", prop: "othersource", isFilterable: true },
      { title: "Lead Type", prop: "leadtype", isFilterable: true, isSortable: true },
      { title: "Area", prop: "area", isFilterable: true },
      { title: "Other Area", prop: "otherarea", isFilterable: true },
      {
        title: "DOB", prop: "dob", isFilterable: true, cell: (row) => (
          <span>
            {row.dob ? moment(row.dob).format('DD-MM-yyyy') : ""}
          </span>
        )
      },
      {
        title: "Anniversary", prop: "anniversary", isFilterable: true, cell: (row) => (
          <span>
            {row.anniversary ? moment(row.anniversary).format('DD-MM-yyyy') : ""}
          </span>
        )
      },
      /*  { title: "User Type", prop: "usertype", isFilterable: true }, */
      {
        title: "Created Date", prop: "createddate", isSortable: true, isFilterable: true, cell: (row) => (
          <span>
            {moment(row.createddate).format('DD-MM-yyyy')}
          </span>
        )
      },
      {
        title: "Assigned",
        prop: "ownername",
        isFilterable: true,
        isSortable: true,
        cell: (row) => {
          const titleAbbreviations = {
            "Sales Admin": "S. A.",
            "Installation Admin": "I. A.",
            "Accounts Admin": "A. A.",
            "Installation Engineer": "I. E.",
            "Business Development Executive": "B. D. E.",
            "Customer Relationship Executive": "C. R. E.",
            "Office Coordinator": "O. C.",
            "Office Executive": "O. E.",
            "Super_Admin": "Super_Admin" // If no abbreviation is needed
          };


          // Split ownername and ownerid arrays
          const ownerNames = row.ownername ? row.ownername.split(', ') : [];
          const ownerIds = row.ownerid ? row.ownerid : [];

          // Function to shorten titles
          const shortenTitle = (name) => {
            const [fullName, title] = name.split(' - ');
            const shortTitle = titleAbbreviations[title.trim()] || title; // Use abbreviation if available
            return `${fullName} - ${shortTitle}`;
          };

          // Ensure ownerNames and ownerIds arrays are of the same length
          const links = ownerNames.map((name, index) => (
            <Link
              key={index} // Use index as key if ownerIds[index] is not reliable
              to={`/users/${ownerIds[index]}`}
              state={row}
              className="align-items-center justify-content-start"
            >
              {shortenTitle(name)}<br />
            </Link>
          ));
          return <span>{links}</span>;
        },
      },
    )
  } else {
    //for mobile device

    header.push(
      {
        title: "Info",
        prop: "leadname",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <div className="mobilecard">
            <Link to={"/leads/" + row.id} state={row} style={{ fontSize: "1.2rem" }}>
              {row.leadname}
            </Link>
            <Link to={"/users/" + row.ownerid} state={row}>
              <i class="fa-solid fa-user"></i> {row.ownername}
            </Link>
            <span><i class="fa-solid fa-phone"></i> {row.phone}</span>
            <span style={{ width: "80%" }}><i class="fa-solid fa-envelope"></i> {row.email}</span>
            <Badge bg={getStatusClass(row.leadstatus)} style={{ paddingBottom: "5px", width: "80%" }}>
              {row.leadstatus}
            </Badge>
          </div>
        ),
      },
    )
  }

  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    //console.log('event',event.target.files)
    setSelectedFile(event.target.files[0]);
  };

  const handleCSVUploadFile = async () => {
    if (selectedFile) {
      console.log('selectedfile', selectedFile)
      setIsLoader(true)
      const formData = new FormData();
      formData.append("file", selectedFile);
      console.log('formData', formData)

      const response = await SparkApi.uploadCSVFile(formData);
      if (response && response?.length) {
        setIsLoader(false)
        setIsCSVFiles(false);
        getLeadsList();
        PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'CSV file saved successfully' });
      } else {
        setIsLoader(false)
        setIsCSVFiles(false);
        getLeadsList();
        PubSub.publish('RECORD_ERROR_TOAST', { title: 'CSV file Error', message: 'Some error occured' });
      }
    } else {
      console.log('no files')
    }
  };



  const createLead = () => {
    navigate(`/leads/e`);
  };

  return (
    <Row className="g-0">

      <Col lg={12} className="px-4">
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 15,
              options: [5, 10, 15, 20],
            },
          }}
        >
          <Row className="gap-0 mb-4">
            <Col
              xs={12}
              sm={6}
              lg={3}
              className="d-flex align-items-center gap-2"
            >
              <div className="mt-4">

                <Filter />
              </div>
              <PaginationOptions labels={labels} />
            </Col>

            <Col
              lg={4}
              md={5}
              sm={6}
              xs={12}
              className="d-flex align-items-center gap-3 mt-4"
            >

                <Form.Group className="" controlId="formBasicStatus">
                  <Form.Select
                    aria-label="Select status"
                    name="leadstatus"
                    onChange={onFilterSelection}
                  >
                    <option value="">---Select Status---</option>
                    {leadStatusArray.map((item, index) => (
                      <option value={item.label} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="" controlId="formBasicArea">
                  <Form.Select name="area" onChange={onFilterSelection}>
                    <option value="">---Select Area---</option>
                    <option value="VKI & Sikar road">VKI & Sikar road</option>
                    <option value="Vidyadhar nagar">Vidyadhar nagar</option>
                    <option value="Bani park">Bani park</option>
                    <option value="Vaishali Nagar">Vaishali Nagar</option>
                    <option value="Vaishali Nagar-West">Vaishali Nagar-West</option>
                    <option value="Mansarovar">Mansarovar</option>
                    <option value="Patrakar colony">Patrakar colony</option>
                    <option value="Sanganer">Sanganer</option>
                    <option value="Gopalpura bypass">Gopalpura bypass</option>
                    <option value="Shyam nagar/Nirman nagar">Shyam nagar/Nirman nagar</option>
                    <option value="Durgapura">Durgapura</option>
                    <option value="JLN Marg">JLN Marg</option>
                    <option value="Malviya Nagar">Malviya Nagar</option>
                    <option value="Bapu Nagar-Lal kothi">Bapu Nagar-Lal kothi</option>
                    <option value="Jagatpura">Jagatpura</option>
                    <option value="C-Scheme-Civil lines">C-Scheme-Civil lines</option>
                    <option value="Jawahar nagar-Raja park">Jawahar nagar-Raja park</option>
                    <option value="Old city">Old city</option>
                    <option value="Amer-Delhi road">Amer-Delhi road</option>
                    <option value="Jhotwara">Jhotwara</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Form.Group>
                
                {userInfo && userInfo?.userrole !== 'USER' &&
                <Form.Group className="" controlId="formBasicUser">
                  <Form.Select
                    aria-label="---Select User---"
                    name="user"
                    onChange={onFilterSelection}
                    >
                    <option value="">---Select User---</option>
                    {users && Array.isArray(users) && users?.map((item) => (<option value={item.value}>{item.label}</option>))}
                  </Form.Select>
                </Form.Group>
                }
              
            </Col>

            <Col
              xs={12}
              sm={6}
              lg={2}
              className="d-flex align-items-center justify-content-center p-0 m-0"
            >
              <BrowserView>
                <InfoPill left="Total" right={body?.length} />
              </BrowserView>
            </Col>

            <Col
              xs={12}
              sm={6}
              lg={3}
              className="mt-4 d-flex align-items-center justify-content-end gap-2"
            >

              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={() => { setIsCSVFiles(true) }}
              >
                Upload File
              </Button>

              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={() => createLead(true)}
              >
                New Lead
              </Button>

              <Button onClick={() => (setChangeOwnerModal(true))} className="d-none btn-sm" >Change Owner</Button>


              {/* <Button className="btn-sm" variant="outline-primary" onClick={()=>location.state ? navigate(`/contacts/${contact.id}`) : navigate('/contacts/')}>Back to Contact</Button> */}

            </Col>

           

          </Row>
          <Row className="">
            <Col
              className="d-flex flex-col justify-content-end align-items-end"
            >
            </Col>
          </Row>
          {body ? (
            <Table striped className="data-table" responsive="sm">
              <TableHeader />
              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}
          <Pagination />
        </DatatableWrapper>
      </Col>
      {isCSVFiles && (
        <Modal
          show={isCSVFiles}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload CSV Files
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {isLoader ?
              (
                <div className="d-flex justify-content-center align-items-center" >
                  <Spinner animation="border" variant="secondary" size="lg" />
                </div>
              ) : (<Form.Control type="file" accept=".csv" onChange={handleFileChange} />)}
          </Modal.Body>
          <Modal.Footer>
            <div className='submit'>
              <Button onClick={handleCSVUploadFile} disabled={selectedFile ? false : true} variant="success">
                Upload
              </Button>
            </div>
            <Button onClick={handleClose} variant="light">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Col lg={2}></Col>
      {changeOwnerModal && <Modal
        show={changeOwnerModal}
        onHide={handelChangeOwnerModalClose}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Owner
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={ChangeOwner} >
          <Modal.Body>
            <Row className="gap-3">
              <Col lg={12} >
                <Row className="">
                  <Col lg={6}>
                    <FormGroup>
                      <FormLabel>From</FormLabel>
                      <Select
                        isClearable
                        options={users}
                        onChange={handelFromUserSelect}
                        // On={()=>{console.log("close")}}
                        name="fromUser"
                        required
                      >
                      </Select>
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <FormLabel>To</FormLabel>
                      <Select
                        isClearable
                        options={users}
                        onChange={handelToUserSelect}
                        name="toUser"
                        required
                      >
                      </Select>
                    </FormGroup>
                  </Col>

                </Row>
              </Col>
              <Col lg={12} className="vh-auto vw-100 overflow-auto">

                <DatatableWrapper
                  body={ownerLeads}
                  headers={ownerLeadsHeader}
                >
                  <Table striped className="data-table" ref={tableRef}>
                    <TableHeader />
                    <TableBody />
                  </Table>
                </DatatableWrapper>

              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <div className='submit'>
              <Button variant="success" type="submit" >
                Change
              </Button>
            </div>
            <Button onClick={handelChangeOwnerModalClose} variant="light">
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>}
    </Row>
  );
};
export default LeadList;
