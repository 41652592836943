import { useEffect, useState } from 'react';
import { Bar, PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, elements, RadialLinearScale, ArcElement } from 'chart.js';
import SparkApi from '../../api/SparkApi';
import { backgroundColors, random_rgba } from "../common/ChartColor";
import { Button } from 'react-bootstrap';


export default function ProductWiseSalesChart({ toDate, fromDate, setChartApi }) {
    const [reportData, setReportData] = useState([]);

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Product wise sales',
            },
        },
    });

    const [data, setData] = useState({ labels: [], datasets: [{}] });

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        RadialLinearScale,
        ArcElement
    );

    // On props date change
    useEffect(() => {
        console.log("date selection", fromDate, toDate)
        async function fetchChartData1() {
            const result = await SparkApi.fetchChartData({
                "apiName": "product_wise_sales_chart",
                "filterColumn": null,
                "date": null,
                "isMonthWiseFilter": false,
                fromDate,
                toDate
            });
            console.log("APi result on from date & to date:- ", result);

            setReportData(result || []);

        }
        if (toDate && fromDate) fetchChartData1();
    }, [toDate, fromDate]);

    // let tempData;
    async function fetchChartData1() {
        const result = await SparkApi.fetchChartData({
            "apiName": "product_wise_sales_chart",
            "filterColumn": null,
            "date": null,
            "isMonthWiseFilter": false
        });
        console.log("APi result =>", result)
        setReportData(result || []);

    }
    useEffect(() => {
        fetchChartData1();
    }, []);
    useEffect(() => {
        if (reportData && reportData.length != 0) {
            console.log("report data = > ", reportData)
            const datasets = [];
            const labels = [...new Set(reportData.map((row) => (row["Sale By"])))] || [];
            console.log("Labels = > ", labels)
            let data = Object.groupBy(reportData, (row) => (row["Product Name"]))
            console.log("Group by product name = > ", data);
            let i = 0;
            for (let key in data) {
                let dataset = {};
                dataset.label = key;
                dataset.data = labels.map((label) => {
                    let res = data[key].find((item) => (item["Sale By"] === label))
                    // console.log("report data  = >  - ",res ? res["Product Count"]: 0)
                    return res ? res["Product Count"] : 0;
                })
                dataset.backgroundColor = backgroundColors[i++];
                datasets.push(dataset);
            }
            console.log("Datasets = > ", datasets)
            datasets.length == 0 && datasets.push({});
            setData({ labels, datasets })
        }
        else {
            setData({ labels: [], datasets: [] });
        }
    }, [reportData]);
    return (
        <>
            {Array.isArray(reportData) && reportData.length != 0 ?
                <>
                    {setChartApi &&<Button className='float-end' onClick={() => (setChartApi("product_wise_sales_chart"))}>Full View</Button>}
                    <Bar data={data} options={options} width={150} height={100} />
                </>
                :

                <div style={{ minHeight: "60vh" }} className='d-flex align-items-center justify-content-center'>
                    <div>
                        <h1> No data</h1>
                    </div>
                </div>

            }
        </>
    );
}



