
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, elements, } from 'chart.js';
import SparkApi from '../../api/SparkApi';
import { backgroundColors, random_rgba } from "../common/ChartColor";
import { Button } from 'react-bootstrap';



function LeadCountByAgentChart({ toDate, fromDate, setChartApi }) {
    const [reportData, setReportData] = useState([]);
    const [options, setOptions] = useState({
        // elements: { bar: { borderWidth: 2, } },
        // maintainAspectRatio: true,
        // responsive: true,
        // plugins: {
        //     legend: { position: 'bottom' },
        //     title: { display: false, text: 'Chart.js Horizontal Bar Chart' },
        // },
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        },
        responsive: true,
        plugins: {

            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart',
            },
        },
    });

    const [data, setData] = useState({ labels: [], datasets: [{}] });

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    async function fetchChartData() {
        const result = await SparkApi.fetchChartData({
            "apiName": "lead_count_by_agent_chart",
            "filterColumn": null,
            "date": null,
            "isMonthWiseFilter": false
        });
        console.log(result);
        setReportData(result || []);
    }

    useEffect(() => {
        fetchChartData();
    }, []);

    // On props date change
    useEffect(() => {
        console.log("date selection", fromDate, toDate);
        const fetchChartByFromDate = async () => {
            const result = await SparkApi.fetchChartData({
                "apiName": "lead_count_by_agent_chart",
                "filterColumn": null,
                "date": null,
                "isMonthWiseFilter": false,
                fromDate,
                toDate
            });
            console.log("APi result on from date & to date in Lead by agent:- ", result);
            setReportData(result || []);
        };
        if (toDate && fromDate) fetchChartByFromDate();
    }, [toDate, fromDate]);

    useEffect(() => {

        if (reportData && reportData.length != 0) {
            const DATA = reportData;
            const tempOwnerWiseData = [];
            DATA.forEach(element => {
                if (tempOwnerWiseData.length == 0) {
                    tempOwnerWiseData.push({ [element.ownername]: [element] });
                }
                else {
                    let flag = false;
                    tempOwnerWiseData.forEach((data) => {
                        if (data.hasOwnProperty(element.ownername)) {
                            data[element.ownername].push(element)
                            flag = true;
                        }
                    });
                    if (!flag) {
                        tempOwnerWiseData.push({ [element.ownername]: [element] });
                    }
                }
            });

            console.log("According to Owner=>", tempOwnerWiseData);

            const OwnerName = (tempOwnerWiseData.flatMap((data) => {
                let name = Object.keys(data)[0];
                return name.trim();
            }));
            console.log("Owner Names", OwnerName);

            let tempAccordingTOStatus = [];
            DATA.forEach(element => {
                if (tempAccordingTOStatus.length == 0) {
                    tempAccordingTOStatus.push({ [element.leadstatus]: [element] });
                }
                else {
                    let flag = false;
                    tempAccordingTOStatus.forEach((data) => {
                        if (data.hasOwnProperty(element.leadstatus)) {
                            data[element.leadstatus].push(element)
                            flag = true;
                        }
                    });
                    if (!flag) {
                        tempAccordingTOStatus.push({ [element.leadstatus]: [element] });
                    }
                }
            });

            tempAccordingTOStatus = tempAccordingTOStatus.map((data, i) => {
                data.label = Object.keys(data)[0];
                data.data = OwnerName.map((owner) => {
                    return ((data[data.label].find((element) => element.ownername.trim() == owner)?.count * 1) || 0)
                });
                data.backgroundColor = backgroundColors[i]
                // data.backgroundColor = random_rgba()

                // data.borderColor = data.backgroundColor;
                return data;
            });

            console.log("After Sort According to Status=>", tempAccordingTOStatus);
            setData({
                labels:
                    OwnerName,
                datasets:
                    [...tempAccordingTOStatus]
            });
        }
        else {
            setData({ labels: [], datasets: [] });
        }
    }, [reportData]);

    return (
        <>
            {Array.isArray(reportData) && reportData.length != 0 ?
                <>
                    {setChartApi && <Button className='float-end' onClick={() => (setChartApi("lead_count_by_agent_chart"))}>Full View</Button>}
                    <Bar data={data} options={options} width={150} height={100} />
                </>
                :

                <div style={{ minHeight: "54vh" }} className='d-flex align-items-center justify-content-center'>
                    <div>
                        <h1> No data</h1>
                    </div>
                </div>

            }
        </>
    );
}

export default LeadCountByAgentChart
