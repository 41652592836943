import { useEffect, useState } from 'react';
import { Bar, PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, elements, RadialLinearScale, ArcElement } from 'chart.js';
import SparkApi from '../../api/SparkApi';
import { backgroundColors, random_rgba } from "../common/ChartColor";
import { Button } from 'react-bootstrap';


export default function DayWiseMeetingChart({setChartApi}) {
    const [reportData, setReportData] = useState([]);

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Business Report',
            },
        },
    });

    const [data, setData] = useState({ labels: [], datasets: [{}] });

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        RadialLinearScale,
        ArcElement
    );
    // let tempData;
    async function fetchChartData() {
        const result = await SparkApi.fetchChartData({
            "apiName": "day_wise_meeting_chart",
            "filterColumn": null,
            "date": null,
            "isMonthWiseFilter": false
        });
        console.log("APi result ", result)
        setReportData(result || []);

    }
    useEffect(() => {
        fetchChartData();
    }, []);

    //  // On props date change
    //  useEffect(() => {
    //     console.log("date selection",fromDate,toDate);
    //     const fetchChartByFromDate = async () => {
    //         const result = await SparkApi.fetchChartData({
    //             "apiName": "day_wise_meeting_chart",
    //             "filterColumn": null,
    //             "date": null,
    //             "isMonthWiseFilter": false,
    //             fromDate,
    //             toDate
    //         });
    //         console.log("APi result on from date & to date in Lead by agent:- ",result);  
    //         setReportData(result || []);
    //     };
    //     if(toDate&&fromDate) fetchChartByFromDate();
    // }, [toDate,fromDate]);

    useEffect(() => {

        let staff = [...new Set(reportData.map((rec) => (rec["Assign Staff"])))];
        console.log(staff);
        // // dates.sort((a, b) => clg);
        // //   console.log(dates)
        const statusWiseObj = Object.groupBy(reportData, (item) => (item["Status"]));
        console.log(statusWiseObj);
        let datasets = [];
        let counter = 0;
        for (let key in statusWiseObj) {
            let dataset = {};
            dataset.label = key;
            dataset.backgroundColor = backgroundColors[counter++];
            dataset.data = staff.map((stf) => {
                let res = (statusWiseObj[key].find((elm) => (elm["Assign Staff"] == stf)));
                return res ? res?.count : 0;
            });
            datasets.push(dataset);
        }
        console.log("datasets", datasets)

        staff = staff || [];
        datasets = datasets.length == 0 ? [] : datasets;

        setData({ labels: staff, datasets })

    }, [reportData]);
    return (
        <>
            {Array.isArray(reportData) && reportData.length != 0 ?
                <>
                    {setChartApi && <Button className='float-end' onClick={() => (setChartApi("day_wise_meeting_chart"))}>Full View</Button>}
                    <Bar data={data} options={options} width={150} height={100} />
                </>

                :

                <div style={{ minHeight: "60vh" }} className='d-flex align-items-center justify-content-center'>
                    <div>
                        <h1> No data</h1>
                    </div>
                </div>

            }
        </>
    );
}



