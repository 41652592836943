import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import SparkApi from "../api/SparkApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Confirm from "./Confirm";
import ContactEditOnClick from "./ContactEditOnClick";

const RealetedLeadTest = ({ parent, refreshPatientTestsList }) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [reletedContact, setReletedContact] = React.useState("");
  const [modalShowPatientTests, setModalShowPatientTests] =
    React.useState(false);
  const [modalShowPatientTestsView, setModalShowPatientTestsView] =
    React.useState(false);
  const [modalShowContact, setModalShowContact] = useState(false);
  // Create table headers consisting of 4 columns.
  const [body, setBody] = useState([]);
  useEffect(() => {
    realetedContactList();
  }, []);

  useEffect(() => {
    realetedContactList();
  }, [refreshPatientTestsList]);

  const realetedContactList = () => {
    async function init() {
      ////console.log("ownerId " + parent.id);
      let parentLeadList = await SparkApi.findContactByAccountId(
        parent.id
      );
      if (parentLeadList && parentLeadList?.length > 0) {
        setBody(parentLeadList);
      } else {
        setBody([]);
      }
    }
    init();
  };
  const handleDelete = (row) => {
    setModalShow(true);
    setReletedContact(row);
  };
  const editLead = (row) => {
    setModalShowContact(true);
    ////console.log("Edit");
    setReletedContact(row.row);
    ////console.log("Row " + JSON.stringify(row.row));
    navigate(`/contacts/${row.row.id}/e`, { state: row.row });
  };
  const submitContact = (row) => {
    setModalShowContact(false);
    realetedContactList();
  };

  //   const labels = {
  //     beforeSelect: " "
  //   }

  const deleteContact = async () => {
    const result = await SparkApi.deleteContact(reletedContact.id);
    if (result.success) {
      setReletedContact("");
      setModalShow(false);
      realetedContactList();
    }
  };

  //   const editPatientTests = (row) => {
  //     setModalShowPatientTests(true)
  //     setReletedContact(row.row);
  //   }

  const header = [
    {
      title: "First Name",
      prop: "firstname",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/contacts/" + row.id} state={row}>
          {row.firstname}
        </Link>
      ),
    },
    { title: "Last Name", prop: "lastname" }, // cell: (row) => (moment(row.tdate).format('DD-MM-YYYY')) },
    { title: "Phone", prop: "phone" },
    { title: "Email", prop: "email" },
    { title: "City", prop: "city" },
    { title: "State", prop: "state" },
    { title: "Country", prop: "country" }, // cell: (row) => (row.totalamount - row.discount) },

    {
      title: "Actions",
      prop: "id",
      cell: (row) => (
        <>
          <Button className="btn-sm mx-2" onClick={() => editLead({ row })}>
            <i className="fa-regular fa-pen-to-square"></i>
          </Button>
          <Button
            className="btn-sm mx-2"
            variant="danger"
            onClick={() => handleDelete(row)}
          >
            <i className="fa-regular fa-trash-can"></i>
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteContact={deleteContact}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="contact"
        />
      )}

      {/* {modalShowContact &&
         
        <ContactEditOnClick
        show={modalShowContact}
        onHide={() => setModalShowContact(false)}
        parent={parent}
        reletedContact={reletedContact}
        submitContact={submitContact}
      /> 
        } */}

      {body ? (
        <DatatableWrapper
          body={body}
          headers={header}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5,
            },
          }}
        >
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            ></Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            ></Col>
          </Row>
          <Table striped className="related-list-table" responsive = "sm">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />
        </DatatableWrapper>
      ) : (
        ""
      )}
    </>
  );
};

export default RealetedLeadTest;
