import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import PubSub from "pubsub-js";
import CityState from "../constants/CityState.json";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import jwt_decode from "jwt-decode";

const AccountEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [options, setOptions] = useState([]);
  const [name, setName] = useState("");
  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [accountname, seAccounttName] = useState("");
  const [selectUser, setSelectUser] = useState({});
  const [selectOwner, setOwnerSelect] = useState([]);
  let userInfo;
  const [show, setShow] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  //const [account, setaccount] = useState(location.state);
  const [account, setAccount] = useState(location.state ? location.state : {});
  ////console.log("Account name => ",account.name)
  {
    account.id != null && name === "" ? setName(account.name) : <h1>{null}</h1>;
  }

  useEffect(() => {
    let userInfo = jwt_decode(localStorage.getItem('token'));
    let fetchedAccounts = [];
    accounts.map((item) => {
      fetchedAccounts.push({
        label: item.name,
        population: 4780127,
        capital: "Montgomery",
        region: "South",
      });
    });
    setOptions(fetchedAccounts);

    //////console.log('CityState',CityState)
    let st = [];
    CityState.map((item) => {
      //////console.log(item.state,item.id);
      var obj = {};
      obj.value = item.state;
      obj.label = item.state;
      //////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });

    let finalStates = {};
    st = st.filter(function (currentObject) {
      if (currentObject.value in finalStates) {
        return false;
      } else {
        finalStates[currentObject.value] = true;
        return true;
      }
    });

    setState(st);

    async function init() {
      const fetchUser = await SparkApi.fetchUsers();
      ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
      let usr = []
      fetchUser.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.username + " - " + item.usertype;
        obj.usertype = item.usertype;
        ////console.log(" obj.label >" + obj.label)
        usr.push(obj);
      })
      console.log('usr', usr)
      setSelectUser(usr);

      if (location?.state) {
        if (location?.state.ownerid) {
          const initialSelection = location?.state.ownerid.map(id => ({
            value: id,
            label: usr.find(user => user.value === id)?.label || '' + " - " + usr.find(user => user.value === id)?.usertype || '',
            usertype: usr.find(user => user.value === id)?.usertype || ''
          }));
          setOwnerSelect(initialSelection);
        }
      } else {
        let temp = {};
        temp.value = userInfo.id;
        temp.label = userInfo.username + " - " + userInfo?.usertype;
        temp.usertype = userInfo.usertype;
        setOwnerSelect([temp]);
        account.ownerid = [userInfo.id];
      }
      if(account && account?.state){
        let filteredCities = [];
        CityState.forEach(function (obj) {
          if (obj.state === account?.state) {
            filteredCities.push({
              label: obj.name,
              value: obj.name,
            });
          }
        });
        setCities(filteredCities);
      }
    }
    init();
  }, [accounts]);

  const handleChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setAccount({ ...account, state: e.value,city: "" });
    // setAccount({ ...account, state: e.value });
  };

  const handleSelectListChange = (value, name) => {
    ////console.log("account:", account);
    setAccount({ ...account, [name]: value.value });
    setSelectedCity(value.value);
  };

  const handleOwnerSelect = (selectedList) => {
    //console.log('selectedList', selectedList)
    (selectedList?.length !=0 )?setShow(false):setShow(true);
    console.log("selectedList",selectedList.length);
    setOwnerSelect(selectedList);
    //console.log('selectedList.map(item => item.value);', selectedList.map(item => item.value))
    account.ownerid = selectedList.map(item => item.value);
    //console.log('lead.ownerid', lead.ownerid)
    // handleChange({ target: { name: 'product', value: selectedList.map(item => item.value) } });
  };

  const checkRequredFields = () => {
    //Created a REGEX for the email validation 5 Sept 2024 @ali 
    const flags = "g";
    const pattern = "^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}$";
    const regexPattern = new RegExp(pattern, flags);
    if (selectOwner && selectOwner?.length === 0) {
      console.log('if call');
      setShow(true);
      // document.querySelector(".username").style.borderLeftColor = "red";

    } else if (
      account.name &&
      account.name.trim() !== "" &&
      account.phone &&
      account.phone.trim() !== "" &&
      account.phone.length == 10 &&
      account.email &&
      account.email.trim() !== "" &&
      account.email.match(regexPattern) &&
      account.website &&
      account.website.trim() !== "" &&
      account.ownerid && account?.ownerid?.length > 0
      && account.area && account.area.trim() !== ""
    ) {

      if (account.area === "Other") {
        if (account.otherarea && account.otherarea.trim() !== "") return false;
      } else {
        account.otherarea = "";
        return false;
      }
    }
    // document.querySelector(".username").style.borderLeftColor = "green";
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkRequredFields()) {
      setValidated(true);
      return;
    }

    //========= Logic to perform Create or Edit ======
    let result = {};
    if (account.id) {
      result = await SparkApi.saveAccount(account);
      ////console.log(result)
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/accounts/${account.id}`, { state: account });
      }
    } else {
      result = await SparkApi.createAccount(account);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/accounts/${result.id}`, { state: result });
      }
    }
  };

  const handleCancel = () => {

    if (account.id) {
      navigate("/accounts/" + account.id, { state: account });
    } else {
      navigate("/accounts/");
    }

  };

  return (
    <Container className="view-form">
      <Row >
        <Col></Col>
        <Col lg={8} className="pb-1 pt-2">
          <Link className="nav-link" to="/accounts">
            Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Accounts</div>
          </Link>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            <Row className="view-form-header align-items-center">

              <Col lg={8}>
                {/* Edit Account */}
                {
                  account.name === undefined ? <h6>Create Account</h6> : <h6>Edit Account</h6>
                }
                <h5>{name}</h5>

              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row className="ibs-edit-form">

              <Row lg={12} className="mb-4">
                <Alert variant="danger" show={show} className="error-alert my-3 mx-2">
                  Please select assign staff
                </Alert>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicName">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicName"
                    >
                      Account Name
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      required
                      type="text"
                      name="name"
                      placeholder="Enter Account Name"
                      value={account.name}
                      onChange={handleChange}
                     
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide account name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>


                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Phone
                    </Form.Label>
                    <Form.Control
                      required
                      style={{ height: "37px" }}
                      type="phone"
                      name="phone"
                      minLength="10"
                      maxLength="10"
                      placeholder="Enter Phone"
                      value={account.phone}
                      // pattern="^[^A-Za-z]+$"
                      onKeyDownCapture={e => {  (e.keyCode == 32||((e.key.length == 1 ) && isNaN(e.key))) && e.preventDefault()}}

                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide valid Phone.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicEmail">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      required
                      style={{ height: "37px" }}
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      value={account.email}
                      onChange={handleChange}
                      pattern="^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}$"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide valid email
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicwebsite">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicwebsite"
                    >
                      Website
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      required
                      type="text"
                      name="website"
                      placeholder="Enter Website"
                      value={account.website}
                      onChange={handleChange}
                      // pattern="[A-Za-z.@0-9]{2,}"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide website name
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6} >
                  <Form.Group className={`mx-3`} controlId="user" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="user"
                    >
                      Assigned User
                    </Form.Label>

                    <MultiSelect
                      required
                      className={`multi-staff custom-select rounded-3 ${show ? 'border-start-2 border-danger':'border-start-2 border-success'} `} 
                      options={selectUser}
                      value={selectOwner}
                      onChange={handleOwnerSelect}
                      labelledBy="-- Select Assigned--"
                      // name="username"
                     
                      // name="user"
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      Please provide Assigned.
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicState">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicState"
                    >
                      State
                    </Form.Label>
                    <Select
                      placeholder="Select State"
                      value={account.state && {
                        label: account.state,
                        value: account.state,
                      }}
                      onChange={handleState}
                      options={state}
                      // name="state"
                     
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCity">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCity"
                    >
                      City
                    </Form.Label>
                    <Select
                      // required
                      // className="custom-select"
                      options={cities}
                      onChange={(e) => {
                        handleSelectListChange(e, "city");
                      }}
                      name="city"
                      value={account.city && { label: account.city, value: account.city }}
                      placeholder="Select City"
                    />
                  </Form.Group>
                </Col>


                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicStreet">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStreet"
                    >
                      Street
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      type="text"
                      name="street"
                      placeholder="Enter Street"
                      value={account.street}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPin">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPin"
                    >
                      Pincode
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      type="text"
                      name="pincode"
                      placeholder="Enter Pincode"
                      value={account.pincode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCountry">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCountry"
                    >
                      Country
                    </Form.Label>
                    <Form.Control
                      style={{ height: "37px" }}
                      type="text"
                      name="country"
                      placeholder="Enter Country"
                      value={account.country}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} >
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Area
                    </Form.Label>
                    <Form.Select style={{ height: "37px" }} required value={account.area} name="area" onChange={handleChange} placeholder="Select Area">
                      <option value="">--Select Area--</option>
                      <option value="VKI & Sikar road">VKI & Sikar road</option>
                      <option value="Vidyadhar nagar">Vidyadhar nagar</option>
                      <option value="Bani park">Bani park</option>
                      <option value="Vaishali Nagar">Vaishali Nagar</option>
                      <option value="Vaishali Nagar-West">Vaishali Nagar-West</option>
                      <option value="Mansarovar">Mansarovar</option>
                      <option value="Patrakar colony">Patrakar colony</option>
                      <option value="Sanganer">Sanganer</option>
                      <option value="Gopalpura bypass">Gopalpura bypass</option>
                      <option value="Shyam nagar/Nirman nagar">Shyam nagar/Nirman nagar</option>
                      <option value="Durgapura">Durgapura</option>
                      <option value="JLN Marg">JLN Marg</option>
                      <option value="Malviya Nagar">Malviya Nagar</option>
                      <option value="Bapu Nagar-Lal kothi">Bapu Nagar-Lal kothi</option>
                      <option value="Jagatpura">Jagatpura</option>
                      <option value="C-Scheme-Civil lines">C-Scheme-Civil lines</option>
                      <option value="Jawahar nagar-Raja park">Jawahar nagar-Raja park</option>
                      <option value="Old city">Old city</option>
                      <option value="Amer-Delhi road">Amer-Delhi road</option>
                      <option value="Jhotwara">Jhotwara</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide Area.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {account?.area === 'Other' &&
                  <Col lg={6} >
                    <Form.Group className="mx-3" >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Other Area
                      </Form.Label>
                      <Form.Control
                        style={{ height: "37px" }}
                        required
                        type="text"
                        name="otherarea"
                        placeholder="Enter Other Area"
                        value={account.otherarea}
                        //defaultValue="Ali"
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide other area
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>}
                <Row></Row>
              </Row>
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};
export default AccountEdit;
