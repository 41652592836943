import React, { useState, useEffect,  forwardRef, useImperativeHandle, useRef  } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import SparkApi from "../api/SparkApi";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Confirm from './Confirm';
import BusinessEdit from './BusinessEdit';
import BusinessView from './BusinessView';

const RelatedListBusiness = ({parent, refreshBusinessList}) => {


  const [modalShow, setModalShow] = React.useState(false);
  const [business, setBusiness] = React.useState('');
 
  const navigate = useNavigate();
  // Create table headers consisting of 4 columns.
  
  const [body, setBody] = useState([]);
  useEffect(() => {
    //////console.log(parent.id);
    businessList();
    
  }, []);

  useEffect(() => {
    businessList();
    
  }, [refreshBusinessList]);
  
  const businessList = () => {
    async function init() {
      let businesses = await SparkApi.fetchBusinessByParent(parent.id);
    
      //console.log(businesses);
      if (businesses && businesses?.length > 0) {
        setBody(businesses);
      } else {
        setBody([]);
      }
    }
    init();
  }
  const handleDelete = (row) => {
    setModalShow(true);
    setBusiness(row);
  }

  const labels = {
    beforeSelect: " "
  }

  


  const editBusiness = (row) => {
    //console.log(row)
    navigate(`/business/${row.row.id}/e`, {state : row.row});
  }

  const viewBusiness = (row) => {
    
  }

  

  const header = [
    {
      title: 'Name', prop: 'name',
      cell: (row) => (
        <Link to={"/business/" + row.id}>
        {row.name}
      </Link>
      )
    },
    { title: 'Amount', prop: 'amount' },
    { title: 'Owner', prop: 'ownername' },
  
    { title: 'Created Date', prop: 'createddate', cell: (row) => (moment(row.createddate).format('DD-MM-YYYY')) },
    {
      title: 'Actions', prop: 'id', cell: (row) => (
        <><Button className='btn-sm mx-2' onClick={() => editBusiness({ row })}><i className="fa-regular fa-pen-to-square"></i></Button>
        </>
      )
    }
  ];


  return (
    <>
    


      {body ?



        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >


            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >


            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
            </Col>
          </Row>
          <Table striped className="related-list-table" responsive = "sm">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />
        </DatatableWrapper> : ''}
    </>
  )
};

export default RelatedListBusiness