import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

import { Link } from "react-router-dom";

const DashboardList = () => {
  let navigate = useNavigate();

  const [body, setBody] = useState([]);
  const [show, setShow] = useState(false);
//   const [showReportBuilder, setshowReportBuilder] = useState(false);
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

//   const [tableNameValue, settableNameValue] = useState();

  const [dashboard, setDashboard] = useState([]);
  const [disableButton,sitDisableButton]=useState(true);



useEffect(() => {
    async function init() {
        fetchAllDashboard();
    }
    init();
  }, []);

  const fetchAllDashboard = async () => {
    //console.log("fetchAllDashboard CALL");
    const reports = await SparkApi.fetchAllDashboard();
    //console.log("reports",reports);
    if (reports) {
      setBody(reports);
    } else {
      setBody([]);
    }
  };

  const header = [
    {
      title: "Dashboard Name",
      prop: "name",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/dashboards/" + row.id} state={row.id}>
          {row.name}
        </Link>
      ),
    },
    { title: "Discription", prop: "description", isFilterable: true},
    {
      title: "Created Date",
      prop: "createddate",
      cell: (row) => moment(row.createddate).format("DD-MM-YYYY"),
      isFilterable: true,
    },
    {
      title: "LastModified Date",
      prop: "lastmodifieddate",
      cell: (row) => moment(row.lastmodifieddate).format("DD-MM-YYYY"),
      isFilterable: true,
    },
  ];

  const labels = {
    beforeSelect: " ",
  };

 



  const handleDashboard = (event) => {
    //console.log("handleDashboard", event.target.value);
    sitDisableButton(false);
    setDashboard(event.target.value);
    setDashboard({ ...dashboard, [event.target.name]: event.target.value });

  };

  const handleCreate = () => {
    navigate("/dashboard",{state: dashboard});
  };

  return (
    <Row className="g-0">
    
        <Col lg={12} className="px-4">
          {body ? (
            <DatatableWrapper
              body={body}
              headers={header}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 10,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={() => setShow(true)}
                  >
                    Create Dashboard
                  </Button>
                </Col>
              </Row>
              <Table striped className="data-table" responsive="sm">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            ""
          )}
        </Col>
    

      {/* MODEL TABLES START */}
      <Modal
        size="md"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b className="App">
             <span className="report-name">NEW DASHBOARD</span>
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>      
          <Form.Group className=" mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="form-view-label">
              <span className="report-name">Name</span>
            </Form.Label>
            <Form.Control
              type="text"
              
              name="name"
              value={dashboard.name} 
              onChange={handleDashboard}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>
           <span className="report-name">Description</span> 
             
            </Form.Label>
            <Form.Control as="textarea"   onChange={handleDashboard}  name="description" value={dashboard.description} />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleCreate} disabled={disableButton}>
                Next
            </Button>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
             
          
        </Modal.Footer>
      </Modal>
      {/* MODEL END */}

      {/* {showReportBuilder && <ReportBuilder data={tableNameValue} />} */}
    </Row>
  );
};
export default DashboardList;
