import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import SparkApi from "../api/SparkApi";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Confirm from './Confirm';
import TaskEdit from './TaskEdit';
import TaskView from './TaskView';
import Modal from "react-bootstrap/Modal";

const RelatedListLocationHistory = ({ parent, refreshTaskList }) => {
    const [validated, setValidated] = useState(false);
    console.log('parent', parent)
    const [modalShow, setModalShow] = React.useState(false);
    const [usertracking, setUsertracking] = React.useState('');
    const [modalShowTask, setModalShowTask] = React.useState(false);
    const [modalShowTaskView, setModalShowTaskView] = React.useState(false);
    const [description, setDescription] = React.useState('');

    // Create table headers consisting of 4 columns.

    const [body, setBody] = useState([]);
    useEffect(() => {
        //////console.log(parent.id);
        taskList();

    }, []);

    useEffect(() => {
        taskList();

    }, [refreshTaskList]);

    const taskList = () => {
        async function init() {
            let tasks = await SparkApi.fetchLeadLocations(parent.id);
            console.log('locations', tasks);
            if (tasks && tasks?.length > 0) {
                setBody(tasks);
            } else {
                setBody([]);
            }
        }
        init();
    }
    const handleDelete = (row) => {
        setModalShow(true);
        setUsertracking(row);
    }

    const labels = {
        beforeSelect: " "
    }

    const deleteTask = async () => {
        ////console.log('task.id',task.id)
        const result = await SparkApi.deleteTask(usertracking.id);
        ////console.log('result',result)
        if (result.success) {
            setUsertracking('');
            setModalShow(false);
            taskList();
        }
    }

    const handleChangeSubmit = async (e) => {
        e.preventDefault();

        if (usertracking?.location && usertracking?.location.trim() !== '') {
            let res = await SparkApi.handlUsertrackings(usertracking)
            if (res) {
                setModalShowTask(false);
                taskList();
            }
        } else {
            setValidated(true);
            return;
        }
        //console.log('description',description)
    }

    const editUserTracking = (row) => {
        setModalShowTask(true)
        setUsertracking(row.row);
    }

    const handleClose = () => setModalShowTask(false);

    const viewTask = (row) => {
        setModalShowTaskView(true)
        setUsertracking(row);
    }



    const header = [

        { title: 'User Name', prop: 'username' },
        { title: 'Location', prop: 'location' },
        {
            title: 'Login Time',
            prop: 'logindatetime',
            cell: (row) => (moment(row.logindatetime).format('DD-MM-YYYY HH:mm:ss'))
        }, {
            title: 'Actions', prop: 'id', cell: (row) => (
                <>
                    <Button className='btn-sm mx-2' onClick={() => editUserTracking({ row })}><i className="fa-regular fa-pen-to-square"></i></Button>
                </>
            )
        }


    ];


    return (
        <>



            {body ?



                <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 5
                    }
                }}>
                    <Row className="mb-4">
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >


                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >


                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                        </Col>
                    </Row>
                    <Table striped className="related-list-table" responsive="sm">
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Pagination />
                </DatatableWrapper> : ''}
            {modalShowTask &&
                <>
                    <Modal show={modalShowTask} onHide={handleClose} >
                        <Modal.Header closeButton>
                            <Modal.Title>Location History</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col></Col>
                                <Col lg={12}>
                                    <Form noValidate validated={validated} className="mt-3" >
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formBasicDescription">
                                                    <Form.Label
                                                        className="form-view-label"
                                                        htmlFor="formBasicDescription"
                                                    >
                                                        Location
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="location"
                                                        placeholder="Enter Location"
                                                        onChange={(e) => setUsertracking({ ...usertracking, location: e.target.value })}
                                                        required
                                                        value={usertracking?.location}
                                                    />
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                    </Form>
                                </Col>
                                <Col></Col>
                            </Row>


                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="primary" onClick={handleChangeSubmit}>
                                Save
                            </Button>
                            <Button variant="danger" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    )
};

export default RelatedListLocationHistory