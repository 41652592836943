/* Start updated by Pooja Vaishnav and it will create new incident and updates in the selected 
  incident by using incident id*/

import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PubSub from 'pubsub-js';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import * as constants from '../constants/CONSTANT';
import jwt_decode from "jwt-decode";

const IncidentEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedContact, setSelectedContact] = useState();
    const [incident, setIncident] = useState(location.state ? location.state : {});
    const [validated, setValidated] = useState(false);
    const [selectUser, setSelectUser] = useState({});
    const [name, setName] = useState("");
    const [option, setoption] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let usr=[];
                let con=[]
                const product = await SparkApi.fetchProducts();
                product.map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.productname;
                    //console.log(" obj.label for product >" + obj.label)
                    usr.push(obj);
                })
                setProducts(usr);
                const contact = await SparkApi.fetchContacts();
                contact.map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.contactname;
                    //console.log(" obj.label for product >" + obj.label)
                    con.push(obj);
                })
                //console.log('con@@=>',con)
                setContacts(con);   
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();


    }, []);

    const [show, setShow] = React.useState(false);
    let userInfo;
    useEffect(() => {
        let userInfo = jwt_decode(localStorage.getItem('token'));
        if (location?.state) {
            if (incident.id) {
                let temp = {}
                temp.value = location.state.ownerid;
                temp.label = location.state.ownername;
                setoption(temp);

                let tempP = {}
                tempP.value = location.state.productid;
                tempP.label = location.state.productname;
                setSelectedProduct(tempP);


                let tempC = {}
                tempC.value = location.state.contactid;
                tempC.label = location.state.contactname;
                setSelectedContact(tempC);
                

            } else {
                let temp = {};
                temp.value = userInfo.id;
                temp.label = userInfo.username;
                setoption(temp);
                incident.ownername = userInfo.username;
                incident.ownerid = userInfo.id;
            }
        } else {
            let temp = {};
            temp.value = userInfo.id;
            temp.label = userInfo.username;
            setoption(temp);
            incident.ownername = userInfo.username;
            incident.ownerid = userInfo.id;
        }

        async function init() {
            const fetchUser = await SparkApi.fetchUsers();
            let usr = []
            fetchUser.map((item) => {
                var obj = {};
                obj.value = item.id;
                obj.label = item.username;
                //console.log(" obj.label >" + obj.label)
                usr.push(obj);
            })
            setSelectUser(usr);
        }

        init();


    }, []);

    //Access all the value input values 
    const handleChange = (e) => {
        let temp = { ...incident }
        if (e.target.name == 'contact') {
            setSelectedContact(e.target.value);
        } else if (e.target.name == 'product') {
            setSelectedProduct(e.target.value);
        }
        setIncident({ ...incident, [e.target.name]: e.target.value });
    };
    const handleContactChange = (e) => {
        setSelectedContact(e);
        setIncident({ ...incident, 'contactid': e.value, contactname: e.label });
    }   
    const handleProductChange = (e) => {
        setSelectedProduct(e);
        setIncident({ ...incident, 'productid': e.value, productname: e.label });
    }  

    const handleRoleChange = (e) => {
        setoption(e)
        setIncident({ ...incident, 'ownerid': e.value, ownername: e.label });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (checkRequredFields()) {
            setValidated(true);
            return;
        }
        //========= Logic to perform Create or Edit ======
        let result = {};
        if (incident.id) {
            const result = await SparkApi.updateIncident(incident);
            if (result.success) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                navigate(`/incidents/${incident.id}`, { state: incident });
            }
        } else {
            result = await SparkApi.createIncident(incident);
            if (result) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                navigate(`/incidents/${result.id}`, { state: result });
            }
        }
    };

    const checkRequredFields = () => {
        if (incident && incident.subject && option && option.value) {
            return false;
        }
        setShow(true);
        document.querySelector(".username").style.border = "1px solid red";
        return true;
    }

    const handleCancel = () => {
        navigate("/incidents/", { state: incident });
    };

    return (
        <Container className="view-form">
            <Row className="view-form">
                <Col></Col>
                <Col lg={8} className="pb-1 pt-2">
                    <Link className="nav-link" to="/leads">
                        Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Incidents</div>
                    </Link>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={9}>
                                {
                                    name === "" ? <h6>Create Incident</h6> : <h6> Edit Incident</h6>
                                }

                                <h5>{name}</h5>
                            </Col>
                            <Col lg={3} style={{ textAlign: "right" }}>
                                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row className="ibs-edit-form mb-4" lg={12}>

                            <Alert variant="danger" show={show} className="error-alert my-3 mx-2">
                                Please Select Assign Staff
                            </Alert>

                            <Col lg={6}>
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Subject
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="subject"
                                        required={true}
                                        placeholder="Enter Subject"
                                        value={incident.subject}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Subject Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Priority
                                    </Form.Label>

                                    <Form.Select value={incident.priority} name="priority" onChange={handleChange}>
                                        <option value="">--Select Priority--</option>
                                        <option value="High">High</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Low">Low</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Priority.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Status
                                    </Form.Label>

                                    <Form.Select value={incident.status} name="status" onChange={handleChange}>
                                        <option value="">--Select Status--</option>
                                        <option>New</option>
                                        <option value="Working">Working</option>
                                        <option value="Escalated">Escalated</option>
                                        <option value="Closed">Closed</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Waiting for Customer">Waiting for Customer</option>
                                        <option value="Merged">Merged</option>
                                        <option value="Response Received">Response Received</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Status.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Origin
                                    </Form.Label>
                                    <Form.Select value={incident.origin} name="origin" onChange={handleChange}>
                                        <option value="">--Select Origin--</option>
                                        <option value="Phone">Phone</option>
                                        <option value="Email">Email</option>
                                        <option value="Web">Web</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Origin.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicLeadSource"
                                >
                                    Contact
                                </Form.Label>
                                <Select
                                    name="contact"
                                    value={selectedContact}
                                    className="custom-select username"
                                    onChange={(e) => handleContactChange(e)}
                                    options={contacts}
                                >
                                </Select>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Product
                                    </Form.Label>
                                    <Select
                                        name="product"
                                        value={selectedProduct}
                                        className="custom-select username"
                                        onChange={(e) => handleProductChange(e)}
                                        options={products}
                                    >
                                    </Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Product
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Assigned User
                                    </Form.Label>
                                    <Select
                                        name="assuser"
                                        value={option}
                                        className="custom-select username"
                                        onChange={(e) => handleRoleChange(e)}
                                        options={selectUser}
                                    >
                                    </Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Select-Role.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12} >
                                <Form.Group>
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Description
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        placeholder="Enter Description"
                                        value={incident.description}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col className="mt-5"></Col>

                        </Row>

                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
export default IncidentEdit
  /* End updated by Pooja Vaishnav */