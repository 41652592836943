import React, { useState, useEffect } from "react";
import SparkApi from "../api/SparkApi";

import { Button, Card, Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RelatedLocationHistory, { RelatedCheckInHistory } from "./RelatedLocationHistory";
import PubSub from 'pubsub-js';

const UserView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(location.state ? location.state : {});
  const [locationHistorysTab, setLocationHistorysTab] = useState(true);
  const [trackingHistoryTab, setTrackingHistoryTab] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  ////console.log('user', user)


  useEffect(() => {
    fetchUserById()
  }, []);

  const handlePasswordOnchange = (e) => {
    ////console.log(e.target.value)
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const fetchUserById = () => {

    if (location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3) {
      user.id = location.pathname.split('/')[2];
      ////console.log('user.id',user.id)
    }

    async function inituser() {
      let result = await SparkApi.fetchUserById(user.id);
      //console.log('result fetch by id', result)
      setUser(result);
    }
    inituser();
  };
  const editUser = (row) => {
    ////console.log("row", row);
    navigate(`/users/${user.id}/e`, { state: user });
  }

  const handleSelect = (key) => {
    // console.log(key)
    if (key === 'login history') {
      setLocationHistorysTab(true);
      setTrackingHistoryTab(false);
    }
    else if (key === 'tracking history') {
      setTrackingHistoryTab(true);
      setLocationHistorysTab(false);
    }
    // console.log(trackingHistoryTab);
    // console.log(locationHistorysTab);
  }

  const handleChangeSubmit = async (e) => {
    ////console.log('user', user);

    e.preventDefault();
    ////console.log('userId', userId);
    //console.log('user', user)

    if (user.password !== '' && user.password === user.confirmpassword ) {
      let newUser = { id: user.id, password: user.password };
      const result = await SparkApi.saveUser(newUser);
      setShowPasswordModal(false)
      PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Password updated successfully' });
      ////console.log('result', result);
    } else {
      PubSub.publish('RECORD_ERROR_TOAST', { title: 'Record Saved Error', message: 'Either password do not match or not entered' });
      ////console.log('Password is null, please enter a valid password.');
    }
  };

  const handleClose = () => setShowPasswordModal(false);


  return (
    <div>

      <Container>
        <Modal show={showPasswordModal} onHide={handleClose} className="modal-sm">
          <Form onSubmit={handleChangeSubmit}  >
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={12}>
                  <Row className="gap-2" >
                    <Col lg={12}>
                      <Form.Group className="mx-3" controlId="formBasicFirstName">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicFirstName"
                        >
                          New Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter new password"
                          onChange={handlePasswordOnchange}
                          required
                          minLength={6}
                        />
                      </Form.Group>
                    </Col>

                    {/* </Row> */}
                    {/* <Row> */}
                    <Col lg={12} >
                      <Form.Group className="mx-3" controlId="formBasicPhone">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicPhone"
                        >
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmpassword"
                          placeholder="Enter confirm password"
                          onChange={handlePasswordOnchange}
                          required
                          minLength={6}

                        />
                      </Form.Group>
                    </Col>
                  </Row>

                </Col>
              </Row>


            </Modal.Body>
            <Modal.Footer>

              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Form>

        </Modal>
        <Row className="view-form">

          <Col lg={11} className="pb-3 pt-2">
            <Link className="nav-link" to="/users">
              Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Users</div>
            </Link>
          </Col>
          <Col></Col>
        </Row>
        <Row className="view-form">
          <Col></Col>
          <Col lg={8}>
            <Row className="view-form-header align-items-center">
              <Col lg={3}>
                User
              </Col>
              <Col lg={9} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={() => editUser(true)}>
                  <i class="fa-regular fa-pen-to-square"></i>
                </Button>
                <Button className="btn" variant="danger" onClick={(e) => { setShowPasswordModal(true) }}>
                  Reset Password
                </Button>
              </Col>
            </Row>
            <Row className="ibs-edit-form py-3">
              <Col lg={6}>
                <label>First Name</label>
                <span>{user.firstname}</span>
              </Col>
              <Col lg={6}>
                <label>Last Name</label>
                <span>{user.lastname}</span>
              </Col>
              <Col lg={6}>
                <label>Email</label>
                <span>{user.email}</span>
              </Col>
              <Col lg={6}>
                <label>Phone</label>
                <span>{user.phone}</span>
              </Col>
              <Col lg={6}>
                <label>User Role</label>
                <span>{user.userrole}</span>
              </Col>
              <Col lg={6}>
                <label>User Type</label>
                <span>{user.usertype}</span>
              </Col>
              <Col lg={6}>
                <label>Manager</label>
                <span>{user.managername}</span>
              </Col>
              <Col lg={6}>
                <label>Active</label>
                <span>{user.isactive === true && (<i class="fa-regular fa-square-check" style={{ fontSize: "1.3rem" }}></i>)}  {user.isactive === false && (<i class="fa-regular fa-square" style={{ fontSize: "1.3rem" }}></i>)}  </span>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>

        <Card bg="light" text="light" className="mb-2 mt-4">
          <Card.Header className="d-flex justify-content-between">
            <Tabs defaultActiveKey="login history" id="uncontrolled-tab-example" onSelect={(key) => handleSelect(key)}>

              <Tab eventKey="login history" title="Login History"></Tab>
              <Tab eventKey="tracking history" title="Tracking History"></Tab>
            </Tabs>



          </Card.Header>
          <Card.Body>

            {user && user.id && locationHistorysTab && (<RelatedLocationHistory parent={user} />)}
            {user && user.id && trackingHistoryTab && (<RelatedCheckInHistory parent={user} />)}

            {/* {filesTab && (<RelatedListFiles parent={staffMember} files={files}/>)} */}
            {/* {locationHistorysTab && (<RelatedLocationHistory parent={user} />)} */}
          </Card.Body>
        </Card>

      </Container>
    </div>
  )
}

export default UserView
