import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Form, Container } from "react-bootstrap";
import { useNavigate, } from 'react-router-dom'
import SparkApi from "../api/SparkApi";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import { Link } from "react-router-dom";
import ReportBuilder from "./ReportBuilder";
import Select from "react-select";
import Confirm from "./Confirm";
export default function ProductReportList() {
  const [body, setBody] = useState([]);
  const [header, setHeader] = useState([]);
  // const header ;

  const fetchAllProducts = async () => {
    const products = await SparkApi.fetchProducts();
    console.log(products)
    if (products) {
      setBody(products);
      console.log(products[0]);
      const includedColumn = ['Product Name', 'Product Code', 'Category', 'Sub Category', 'Unit Count'];

      // Object.keys(products[0]).map((title)=>{
      //   includeColumn.includes(title)
      // })
    } else {
      setBody([])
    }

  }
  useEffect(() => {
    fetchAllProducts();
    setHeader([
      {
        title: "Product Name",
        prop: "productname",
        isFilterable: true, isSortable: true,

        cell: (row) => (
          <Link to={"/product_report/" + row.id} state={row}>
            {row.productname}
          </Link>
        ),
      },
      { title: "Product Code", prop: "productcode", isFilterable: true },
      { title: "Category", prop: "category", isFilterable: true },
      { title: "Subcategory", prop: "subcategory", isFilterable: true },
    ]);
  }, []);
  return (
    <Container fluid >
      <Row>
        
        {<Col lg={12} >

          {body ?
            <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20]
              }
            }}>
              <Row className="pb-4">
                <Col lg={3} className="">
                  <label> </label>
                  <Filter />
                </Col>
                <Col lg={1} className="">
                  <PaginationOptions labels={{ beforeSelect: " " }} />
                </Col>
              </Row>
              <Table striped className="data-table" responsive="sm">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />

            </DatatableWrapper> : ''}
        </Col>}
      </Row>
    </Container>
  )
}
