import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { ShimmerTable } from "react-shimmer-effects";

import { isMobile, MobileView, BrowserView } from 'react-device-detect';

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import moment from "moment";
import InfoPill from "./InfoPill";

const ContactList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [tempbody, setTempBody] = useState();
  const [contacts, setContacts] = useState([]);
  let [filter, setFilter] = useState({});
  useEffect(() => {
    async function init() {
      const result = await SparkApi.fetchContacts();
      ////console.log('result',result)

      if (result) {
        setBody(result);
        setContacts(result);
      } else {
        setBody([]);
        setContacts([]);
      }
    }
    init();
  }, []);
  useEffect(() => {
    if (filter) {
      doFilterData();
    }
  }, [filter]);

  const onFilterSelection = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  }

  const doFilterData = (event) => {

    let tempData = contacts?.filter((item) => {
      if (!filter.title || filter.title === '' || item.title === filter.title) {
        return item;
      }
    });

    tempData = tempData.filter((item) => {
      if (!filter.area || filter.area === '' || item.area === filter.area) {
        return item;
      }
    });

    setBody(tempData);
  }

  const onFilterType = (event) => {
    if (event.target.value === "") {
      setBody(contacts);
    } else {
      setBody(
        contacts.filter((data) => {
          if (
            (data.recordtypeid || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };

  // Create table headers consisting of 4 columns.
  const header = [];
  if (!isMobile) {
    header.push(
      {
        title: "Name",
        prop: "firstname",
        isFilterable: true,
        cell: (row) => (
          <Link to={"/contacts/" + row.id} state={row}>
            {row.firstname} {row.lastname}
          </Link>
        ),
      },
      { title: "Contact Type", prop: "title", isFilterable: true },
      { title: "Other Type", prop: "othertitle", isFilterable: true},
      { title: "Area", prop: "area", isFilterable: true },
      { title: "Other Area", prop: "otherarea", isFilterable: true},
      { title: "Street", prop: "street" },
      { title: "City", prop: "city", isFilterable: true },
      { title: "Email", prop: "email", isFilterable: true },
      { title: "Phone", prop: "phone", isFilterable: true },
      {
        title: "DOB", prop: "dob", isFilterable: true, cell: (row) => (
          <span>
            {row.dob ? moment(row.dob).format('DD-MM-yyyy') : ""}
          </span>
        )
      },
      {
        title: "Anniversary", prop: "anniversary", isFilterable: true, cell: (row) => (
          <span>
            {row.anniversary ? moment(row.anniversary).format('DD-MM-yyyy') : ""}
          </span>
        )
      },
      {
        title: "Staff",
        prop: "ownername",
        isFilterable: true,
        isSortable: true,
        cell: (row) => {
          const titleAbbreviations = {
            "Sales Admin": "S. A.",
            "Installation Admin": "I. A.",
            "Accounts Admin": "A. A.",
            "Installation Engineer": "I. E.",
            "Business Development Executive": "B. D. E.",
            "Customer Relationship Executive": "C. R. E.",
            "Office Coordinator": "O. C.",
            "Office Executive": "O. E.",
            "Super_Admin": "Super_Admin" // If no abbreviation is needed
          };


          // Split ownername and ownerid arrays
          const ownerNames = row.ownername ? row.ownername.split(', ') : [];
          const ownerIds = row.ownerid ? row.ownerid : [];

          // Function to shorten titles
          const shortenTitle = (name) => {
            const [fullName, title] = name.split(' - ');
            const shortTitle = titleAbbreviations[title.trim()] || title; // Use abbreviation if available
            return `${fullName} - ${shortTitle}`;
          };

          // Ensure ownerNames and ownerIds arrays are of the same length
          const links = ownerNames.map((name, index) => (
            <Link
              key={index} // Use index as key if ownerIds[index] is not reliable
              to={`/users/${ownerIds[index]}`}
              state={row}
              className="align-items-center justify-content-start"
            >
              {shortenTitle(name)}<br />
            </Link>
          ));

          return <span>{links}</span>;
        }


      }
      // { title: "Contact Type", prop: "recordtypeid", isFilterable: true },
    )
  } else {
    //for mobile device

    header.push(
      {
        title: "Info",
        prop: "name",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <div className="mobilecard" >
            <Link to={"/contacts/" + row.id} state={row} style={{ width: "100%" }}>
              {row.firstname} {row.lastname}
            </Link>

            <span><i class="fa-solid fa-phone"></i> {row.phone}</span>
            <span style={{ width: "80%" }}><i class="fa-solid fa-envelope"></i> {row.email}</span>

          </div>
        ),
      },
    )
  }
  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createContact = () => {
    navigate(`/contacts/e`);
  };

  return (
    <Row className="g-0">

      <Col lg={12} className="px-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={6}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                <Stack direction="horizontal">
                  <PaginationOptions labels={labels} />
                  <Form.Group className="ms-2 mt-4" controlId="formBasicStatus">
                    <Form.Select name="title" onChange={onFilterSelection}>
                      <option value="">--Select Source--</option>
                      <option value="Architect">Architect</option>
                      <option value="Interior Designer">Interior Designer</option>
                      <option value="Contractor">Contractor</option>
                      <option value="Dealer">Dealer</option>
                      <option value="Client">Client</option>
                      <option value="Google">Google</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Whatsapp">Whatsapp</option>
                      <option value="Email">Email</option>
                      <option value="Cold Calling">Cold Calling</option>
                      <option value="Builder">Builder</option>
                      <option value="Other">Other</option>

                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="ms-2 mt-4" controlId="formBasicStatus">
                    <Form.Select name="area" onChange={onFilterSelection}>
                      <option value="">--Select Area--</option>
                      <option value="VKI & Sikar road">VKI & Sikar road</option>
                      <option value="Vidyadhar nagar">Vidyadhar nagar</option>
                      <option value="Bani park">Bani park</option>
                      <option value="Vaishali Nagar">Vaishali Nagar</option>
                      <option value="Vaishali Nagar-West">Vaishali Nagar-West</option>
                      <option value="Mansarovar">Mansarovar</option>
                      <option value="Patrakar colony">Patrakar colony</option>
                      <option value="Sanganer">Sanganer</option>
                      <option value="Gopalpura bypass">Gopalpura bypass</option>
                      <option value="Shyam nagar/Nirman nagar">Shyam nagar/Nirman nagar</option>
                      <option value="Durgapura">Durgapura</option>
                      <option value="JLN Marg">JLN Marg</option>
                      <option value="Malviya Nagar">Malviya Nagar</option>
                      <option value="Bapu Nagar-Lal kothi">Bapu Nagar-Lal kothi</option>
                      <option value="Jagatpura">Jagatpura</option>
                      <option value="C-Scheme-Civil lines">C-Scheme-Civil lines</option>
                      <option value="Jawahar nagar-Raja park">Jawahar nagar-Raja park</option>
                      <option value="Old city">Old city</option>
                      <option value="Amer-Delhi road">Amer-Delhi road</option>
                      <option value="Jhotwara">Jhotwara</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                  <BrowserView className="d-flex align-items-end">
                    <InfoPill left="Total" right={body?.length} />
                  </BrowserView>
                </Stack>
              </Col>

              <Col
                xs={12}
                sm={6}
                lg={2}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createContact(true)}
                >
                  New Contact
                </Button>
              </Col>
            </Row>
            <Table striped className="data-table" responsive="sm">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          <ShimmerTable row={10} col={8} />
        )}
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};

export default ContactList;
