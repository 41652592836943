import React, { useEffect, useState } from 'react'
import SparkApi from '../../api/SparkApi';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart } from 'chart.js';
import { backgroundColors, random_rgba } from "../common/ChartColor";
import { Button, Col, Row } from 'react-bootstrap';

export default function TaskActivityChart({ toDate, fromDate, setChartApi }) {
    const [reportData, setReportData] = useState([]);
    const [data, setData] = useState({ labels: [], datasets: [{}] });
    Chart.register(
        ArcElement
    )
    async function fetchChartData() {
        const result = await SparkApi.fetchChartData({
            "apiName": "task_activity_chart",
            "filterColumn": null,
            "date": null,
            "isMonthWiseFilter": false
        });

        console.log("Api result", result);

        setReportData(result || []);
    }
    useEffect(() => {
        fetchChartData();
    }, []);


    // On props date change

    useEffect(() => {
        console.log("date selection", fromDate, toDate)
        async function fetchChartData() {
            const result = await SparkApi.fetchChartData({
                "apiName": "task_activity_chart",
                "filterColumn": null,
                "date": null,
                "isMonthWiseFilter": false,
                fromDate,
                toDate
            });
            console.log("APi result in Task Activity on from date & to date:- ", result);

            setReportData(result || []);

        }
        if (toDate && fromDate) fetchChartData();
    }, [toDate, fromDate]);

    useEffect(() => {
        console.log("report data", reportData);
        if (reportData && reportData.length != 0) {
            const labels = [...new Set(reportData.map((rec) => (rec?.status)))];
            const data = reportData.map((rec) => (rec?.count))
            // const data = labels.map((status) => {
            //     // status.
            //     return reportData.filter((rec) => (rec?.status === status)).length;
            // });
            // console.log(labels, data)

            const datasets = [{
                label: 'Task Activity by Status',
                data: data,
                backgroundColor: backgroundColors,
                // backgroundColor: random_rgba(),

                hoverOffset: 4
            }];
            setData({ labels, datasets })
        }
        else {
            setData({ labels: [], datasets: [{}] });
        }
    }, [reportData]);

    return (
        <>
            {Array.isArray(reportData) && reportData.length != 0 ?
                <>
                    {setChartApi && <Button className='float-end' onClick={() => (setChartApi("task_activity_chart"))}>Full View</Button>}
                    <Doughnut data={data} />
                </>
                :

                <div style={{ minHeight: "81vh" }} className='d-flex align-items-center justify-content-center'>
                    <div>
                        <h1> No data</h1>
                    </div>
                </div>

            }
        </>
    )
}
