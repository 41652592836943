import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'
import SparkApi from "../api/SparkApi";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import { Link } from "react-router-dom";
import ReportBuilder from "./ReportBuilder";
import Select from "react-select";
import Confirm from "./Confirm";



const ReportList = () => {

  let navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [show, setShow] = useState(false);
  const [showReportBuilder, setshowReportBuilder] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [tablename, setTablename] = useState([]);
  const [tableNameValue, settableNameValue] = useState()

  const [option, setoption] = useState();
  const [optionTable, setOptionTable] = useState([]);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const reports = await SparkApi.fetchTables();
        //console.log('=======', reports);
        setOptionTable(reports);
        // const capitalizedData = reports.map(obj => {
        //   const capitalizedTableName = obj.table_name.charAt(0).toUpperCase() + obj.table_name.slice(1);
        //   return { table_name: capitalizedTableName };
        // });


        fetchAllReports();
      } catch (error) {
        console.error('Error fetching tables:', error);
        // Handle the error appropriately (e.g., show an error message)
      }
    };

    fetchTables();
  }, []);


  const fetchAllReports = async () => {
    const reports = await SparkApi.fetchAllReports();
    //console.log(reports)
    if (reports) {
      setBody(reports)
    } else {
      setBody([])
    }

  }

  const handleSearch = (event) => {
    //console.log(event.target.value)
    setSearchTerm(event.target.value);
  };

  const filteredOptions = optionTable.filter((item) =>
    item.table_name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const editReport = (row) => {
    //console.log(row);
    //console.log(row.id)
    navigate(`/reportedit/${row.id}`, { state: row })
  };



  const header = [
    {
      title: 'Report Name',
      prop: 'name',
      isFilterable: true,
      cell: (row) => (
        <Link to={"/reports/" + row.id} state={row}>
          {row.name}
        </Link>
      )
    },
    // {
    //   title: 'Report Unique Name',
    //   prop: 'apiname',
    //   isFilterable: true
    // },
    {
      title: 'Custom',
      prop: 'iscustom',
      cell: (row) => (
        <input type="checkbox" disabled checked={row.iscustom} />
      )
    },
    {
      title: 'Created Date',
      prop: 'createddate',
      cell: (row) => moment(row.createddate).format('DD-MM-YYYY'),
      isFilterable: true
    },
    {
      title: 'Last Modified Date',
      prop: 'lastmodifieddate',
      cell: (row) => moment(row.lastmodifieddate).format('DD-MM-YYYY'),
      isFilterable: true
    },
    // {
    //   title: 'Actions',
    //   prop: 'id',
    //   cell: (row) => (
    //     <>

    //       {row.iscustom === true && (
    //         <>
    //           <Button className='btn-sm mx-2' onClick={() => editReport(row)}>
    //             <i className="fa-regular fa-pen-to-square"></i>
    //           </Button>
    //           <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}>
    //             <i class="fa-regular fa-trash-can"></i>
    //           </Button>
    //         </>

    //       )}
    //     </>
    //   )
    // }
  ];


  const labels = {
    beforeSelect: " "
  }

  const handleTableClick = (row) => {
    const selectedTableName = row.table_name;
    //console.log(selectedTableName);
    settableNameValue(selectedTableName);
    setTimeout(() => {
      setshowReportBuilder(true);
    }, 500);

    setShow(false);
  };




  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
    return titleCase;
  }

  const [modalShow, setModalShow] = useState(false);
  const [deleteRow, setdeleterow] = useState({});

  const handleDelete = (row) => {
    setModalShow(true);
    setdeleterow(row)
  }
  const deleteReport = async () => {
    //console.log('deleteReport', deleteRow)

    const result = await SparkApi.deleteReport(deleteRow.id);
    //console.log('report delete result', result)
    if (result.success) {
      setdeleterow('');
      setModalShow(false);
      fetchAllReports();
    }
  };
  return (

    <Row className="g-0">
      {modalShow && (
        <Confirm
          show={modalShow}
          onHide={() => setModalShow(false)}
          deleteReport={deleteReport}
          title="Confirm delete?"
          message="You are going to delete the record. Are you sure?"
          table="report"
        />
      )}
      {showReportBuilder === false ?
        <Col lg={12} className="px-4">

          {body ?
            <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20]
              }
            }}>
              <Row className="mb-4">
                <Col
                  xs={12}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-start align-items-start"
                >
                  <PaginationOptions labels={labels} />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  {/* <Button className="btn-sm" variant="primary" onClick={() => (setShow(true))}>Create Report</Button> */}
                </Col>
              </Row>
              <Table striped className="data-table" responsive="sm">
                <TableHeader />
                <TableBody />
              </Table>
              <Pagination />

            </DatatableWrapper> : ''}
        </Col> : ''}

      {/* MODEL TABLES START */}
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title >Create Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" my-2" controlId="formBasicName">
            <Form.Label className="form-view-label" htmlFor="formBasicName">
              Search Table Name
            </Form.Label>
            <Form.Control
              type="text"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form.Group>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Table Name</th>
              </tr>
            </thead>
            <tbody>
              {filteredOptions.map((value, index, array) => {
                return (
                  <tr key={index}>
                    <td><Link onClick={() => handleTableClick(value)}>{toTitleCase(value.table_name)}</Link></td>
                  </tr>
                );
              })}
            </tbody>
          </Table>


          {/* 
          <Row className="g-0">

            <Col lg={12} className="px-4">
              {body ? (
                <DatatableWrapper
                  body={optionTable}
                  headers={headertable}
                  paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 10,
                      options: [5, 10, 15, 20],
                    },
                  }}
                >
                  <Row className="mb-4">
                    <Col
                      xs={12}
                      lg={4}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Filter />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="d-flex flex-col justify-content-start align-items-start"
                    >
                      <PaginationOptions labels={labels} />
                      
                    </Col>

                  </Row>
                  <Table striped className="data-table" responsive="sm">
                    <TableHeader />
                    <TableBody />
                  </Table>
                  <Pagination />
                </DatatableWrapper>
              ) : (
                ''
              )}
            </Col>
            <Col lg={2}></Col>
          </Row> */}




        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        </Modal.Footer> */}
      </Modal>
      {/* MODEL END */}


      {showReportBuilder && (
        <ReportBuilder data={tableNameValue} />
      )}


    </Row>



  )
}

export default ReportList
