import React, { useEffect, useState } from "react";
import { Col, Row, Table, Form, Badge, Container, Button } from "react-bootstrap";
import { useLocation, } from 'react-router-dom'
import SparkApi from "../api/SparkApi";
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import { Link } from "react-router-dom";
import ReportBuilder from "./ReportBuilder";
import Select from "react-select";
import Confirm from "./Confirm";
import { BrowserView } from "react-device-detect";
import InfoPill from "./InfoPill";

export default function ProductReportView() {
    const [leadStatusArray, setleadStatusArray] = useState(JSON.parse(localStorage.getItem('lead_status')));
    const [body, setBody] = useState([]);
    const [lead, setLead] = useState([]);
    const [header, setHeader] = useState([]);
    const location = useLocation();
    let [filter, setFilter] = useState({});
    useEffect(() => {
        if (filter) {
            doFilterData();
        }
    }, [filter]);

    const doFilterData = (event) => {
        console.log("do filter")
        let tempData = lead.filter((item) => {
            if (!filter.leadstatus || filter.leadstatus === '' || item.leadstatus === filter.leadstatus) {
                return item;
            }
        });

        tempData = tempData.filter((item) => {
            if (!filter.area || filter.area === '' || item.area === filter.area) {
                return item;
            }
        });

        setBody(tempData);
        // setLead(tempData);
    }
    // const header ;
    const onFilterSelection = (event) => {
        setFilter({ ...filter, [event.target.name]: event.target.value });
    }
    const getStatusClass = (status) => {
        ////console.log('status',status)
        let accessStatusRec = leadStatusArray.filter((value, index, array) => {
            if (value.label === status) {

                return true
            }

        });
        ////console.log('accessStatusRec',accessStatusRec)
        if (accessStatusRec && accessStatusRec.length > 0) {

            if (accessStatusRec[0].is_converted === true) {
                ////console.log('if isconverted')

                return 'success';
            } else if (accessStatusRec[0].is_lost === true) {
                return 'secondary';
            } else {
                return 'primary';
            }
        } else {
            return 'secondary';
        }
    }
    const fetchLeadProduct = async () => {
        // console.log("location ==>",location)

        const products = await SparkApi.fetchAllLeadOfProduct(location?.state?.id);
        console.log(products)
        if (products) {
            setBody(products);
            setLead(products);
            let header = [{
                title: "Name",
                prop: "leadname",
                isFilterable: true,
                cell: (row) => (
                    <Link to={"/leads/" + row.id} state={row}>
                        {row.leadname}
                    </Link>
                ),
            },
            { title: "Phone", prop: "phone", isFilterable: true },
            /* { title: "Email", prop: "email", isFilterable: true }, */

            {
                title: "Lead Status",
                prop: "leadstatus",
                isFilterable: true, isSortable: true,
                cell: (row) => {
                    return (
                        <Badge bg={getStatusClass(row.leadstatus)} style={{ display: "block", paddingBottom: "5px" }}>
                            {row.leadstatus}
                        </Badge>
                    );

                },
            },
            /*  { title: "Company", prop: "company", isFilterable: true, isSortable: true }, */
            { title: "Lead Source", prop: "leadsource", isFilterable: true },
            { title: "Other Source", prop: "othersource", isFilterable: true },
            { title: "Lead Type", prop: "leadtype", isFilterable: true },
            { title: "Area", prop: "area", isFilterable: true },
            { title: "Other Area", prop: "otherarea", isFilterable: true },
            {
                title: "DOB", prop: "dob", isFilterable: true, cell: (row) => (
                    <span>
                        {row.dob ? moment(row.dob).format('DD-MM-yyyy') : ""}
                    </span>
                )
            },
            {
                title: "Anniversary", prop: "anniversary", isFilterable: true, cell: (row) => (
                    <span>
                        {row.anniversary ? moment(row.anniversary).format('DD-MM-yyyy') : ""}
                    </span>
                )
            },
            /*  { title: "User Type", prop: "usertype", isFilterable: true }, */
            {
                title: "Created Date", prop: "createddate", isSortable: true, isFilterable: true, cell: (row) => (
                    <span>
                        {moment(row.createddate).format('DD-MM-yyyy')}
                    </span>
                )
            },
            {
                title: "Assigned",
                prop: "ownername",
                isFilterable: true,
                isSortable: true,
                cell: (row) => {
                    console.log("first", row)
                    const titleAbbreviations = {
                        "Sales Admin": "S. A.",
                        "Installation Admin": "I. A.",
                        "Accounts Admin": "A. A.",
                        "Installation Engineer": "I. E.",
                        "Business Development Executive": "B. D. E.",
                        "Customer Relationship Executive": "C. R. E.",
                        "Office Coordinator": "O. C.",
                        "Office Executive": "O. E.",
                        "Super_Admin": "Super_Admin" // If no abbreviation is needed
                    };


                    // Split ownername and ownerid arrays
                    const ownerNames = row.ownername ? row.ownername.split(', ') : [];
                    const ownerIds = row.ownerid ? row.ownerid : [];

                    // Function to shorten titles
                    const shortenTitle = (name) => {
                        const [fullName, title] = name.split(' - ');
                        const shortTitle = titleAbbreviations[title.trim()] || title; // Use abbreviation if available
                        return `${fullName} - ${shortTitle}`;
                    };

                    // Ensure ownerNames and ownerIds arrays are of the same length
                    const links = ownerNames.map((name, index) => (
                        <Link
                            key={index} // Use index as key if ownerIds[index] is not reliable
                            to={`/users/${ownerIds[index]}`}
                            state={row}
                            className="align-items-center justify-content-start"
                        >
                            {shortenTitle(name)}<br />
                        </Link>
                    ));
                    return <span>{links}</span>;
                },
            }]
            setHeader(header);
            //   Object.keys(products[0]).map((title)=>{

            //   })
        } else {
            setBody([])
            setLead([])
        }

    }
    useEffect(() => {
        console.log("location - ", location?.state?.id);
        fetchLeadProduct();
    }, []);
    return (
        <Container fluid className="px-4 py-2">
            <Row>
                <Col lg={3}>
                    <Row className="view-form">
                        <Col lg={11} className="pt-3">
                            <Link className="nav-link" to="/product_report">
                                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                                <div style={{ color: "#23468c", display: "inline" }}>Product Report</div>
                            </Link>
                        </Col>

                    </Row>
                </Col>
                <Col lg={12} className="m-3 py-3 bg-white rounded">
                    <h5>Product Name : <Badge>{location.state.productname}</Badge></h5>
                </Col>
                <Col lg={12} className="px-3">
                    {body ?
                        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                            initialState: {
                                rowsPerPage: 15,
                                options: [5, 10, 15, 20]
                            }
                        }}>
                            <Row className="mb-4">
                                <Col
                                    xs={12}
                                    lg={3}
                                    className="d-flex flex-col justify-content-end align-items-end"
                                >
                                    <Filter />
                                </Col>
                                <Col
                                    xs={12}
                                    sm={6}
                                    lg={7}
                                    className="d-flex flex-col justify-content-start align-items-center"
                                >
                                    <PaginationOptions labels={{ beforeSelect: " " }} />

                                    <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                                        <Form.Select
                                            aria-label="Enter status"
                                            name="leadstatus"
                                            onChange={onFilterSelection}
                                        >
                                            <option value="">--Select Status--</option>
                                            {leadStatusArray.map((item, index) => (
                                                <option value={item.label} key={index}>
                                                    {item.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mx- mt-4" controlId="formBasicStatus">
                                        <Form.Select name="area" onChange={onFilterSelection}>
                                            <option value="">--Select Area--</option>
                                            <option value="VKI & Sikar road">VKI & Sikar road</option>
                                            <option value="Vidyadhar nagar">Vidyadhar nagar</option>
                                            <option value="Bani park">Bani park</option>
                                            <option value="Vaishali Nagar">Vaishali Nagar</option>
                                            <option value="Vaishali Nagar-West">Vaishali Nagar-West</option>
                                            <option value="Mansarovar">Mansarovar</option>
                                            <option value="Patrakar colony">Patrakar colony</option>
                                            <option value="Sanganer">Sanganer</option>
                                            <option value="Gopalpura bypass">Gopalpura bypass</option>
                                            <option value="Shyam nagar/Nirman nagar">Shyam nagar/Nirman nagar</option>
                                            <option value="Durgapura">Durgapura</option>
                                            <option value="JLN Marg">JLN Marg</option>
                                            <option value="Malviya Nagar">Malviya Nagar</option>
                                            <option value="Bapu Nagar-Lal kothi">Bapu Nagar-Lal kothi</option>
                                            <option value="Jagatpura">Jagatpura</option>
                                            <option value="C-Scheme-Civil lines">C-Scheme-Civil lines</option>
                                            <option value="Jawahar nagar-Raja park">Jawahar nagar-Raja park</option>
                                            <option value="Old city">Old city</option>
                                            <option value="Amer-Delhi road">Amer-Delhi road</option>
                                            <option value="Jhotwara">Jhotwara</option>
                                            <option value="Other">Other</option>
                                        </Form.Select>
                                    </Form.Group>

                                    {/* <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                <Form.Select
                aria-label="Enter status"
                  name="usertype"
                  onChange={onFilterSelection}
                  >
                  <option value="">--Select User Type--</option>
                  <option value="Sales Admin">Sales Admin</option>
                  <option value="Installation Admin">Installation Admin</option>
                  <option value="Accounts Admin">Accounts Admin</option>
                </Form.Select>
                </Form.Group> */}

                                    <BrowserView>
                                        <InfoPill left="Total" right={body?.length} />
                                    </BrowserView>
                                </Col>
                            </Row>
                            <Table striped className="data-table" responsive="sm">
                                <TableHeader />
                                <TableBody />
                            </Table>
                            <Pagination />

                        </DatatableWrapper> : ''}
                </Col>
            </Row>
        </Container>
    )
}
