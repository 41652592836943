import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import PubSub from "pubsub-js";
import Select from "react-select";
import CityState from "../constants/CityState.json";
import * as constants from '../constants/CONSTANT';
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import jwt_decode from "jwt-decode";

const ContactEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionAccount, setOptionAccount] = useState({});
  const [name, setName] = useState("");
  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [option, setoption] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  //const [contact, setContact] = useState(location.state);
  const [contact, setContact] = useState(location.state ? location.state : {});
  const [selectUser, setSelectUser] = useState({});
  const [selectOwner, setOwnerSelect] = useState([]);
  let userInfo;
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    let userInfo = jwt_decode(localStorage.getItem('token'));
    if (contact.id) {
      let temp = {}
      temp.value = contact.accountid;
      temp.label = contact.accountname;
      setoption(temp);
    }

    let st = [];
    CityState.map((item) => {
      //////console.log(item.state,item.id);
      var obj = {};
      obj.value = item.state;
      obj.label = item.state;
      //////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });

    let finalStates = {};
    st = st.filter(function (currentObject) {
      if (currentObject.value in finalStates) {
        return false;
      } else {
        finalStates[currentObject.value] = true;
        return true;
      }
    });

    setState(st);


    // For add cities in dropdown according to contact.state (For edit contact)
    if (contact.id && contact?.state) {
      let filteredCities = [];
      CityState.forEach(function (obj) {
        if (obj.state === contact?.state) {
          filteredCities.push({
            label: obj.name,
            value: obj.name,
          });
        }
      });
      setCities(filteredCities);
    }

    let fetchedAccounts = [];
    accounts.map((item) => {
      fetchedAccounts.push({
        label: item.firstname,
        population: 4780127,
        capital: "Montgomery",
        region: "South",
      });
    });
    setOptions(fetchedAccounts);
    async function init() {
      const fetchUser = await SparkApi.fetchUsers();
      ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
      let usr = []
      fetchUser.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.username + " - " + item.usertype;
        obj.usertype = item.usertype;
        ////console.log(" obj.label >" + obj.label)
        usr.push(obj);
      })
      //console.log('usr', usr)
      setSelectUser(usr);

      if (location?.state) {
        if (location?.state.ownerid) {
          const initialSelection = location?.state.ownerid.map(id => ({
            value: id,
            label: usr.find(user => user.value === id)?.label || '' + " - " + usr.find(user => user.value === id)?.usertype || '',
            usertype: usr.find(user => user.value === id)?.usertype || ''
          }));
          setOwnerSelect(initialSelection);
        }
      } else {
        let temp = {};
        temp.value = userInfo.id;
        temp.label = userInfo.username + " - " + userInfo?.usertype;
        temp.usertype = userInfo.usertype;
        setOwnerSelect([temp]);
        contact.ownerid = [userInfo.id];
      }


    }
    init();
  }, [accounts]);

  useEffect(() => {
    async function init() {
      const accounttData = await SparkApi.fetchAccounts();
      ////console.log("accounttData" + JSON.stringify(accounttData));
      let acc = [];
      accounttData?.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.name;
        ////console.log(" obj.label >" + obj.label);
        acc.push(obj);
      });
      setOptionAccount(acc);
    }

    init();
  }, []);

  const handleAccount = (e) => {
    // console.log('e', e)
    setoption(e);
    setContact({ ...contact, accountid: e.value, accountname: e.label });
  };

  const handleOwnerSelect = (selectedList) => {
    //console.log('selectedList', selectedList)
    (selectedList?.length != 0) ? setShow(false) : setShow(true);
    setOwnerSelect(selectedList);
    //console.log('selectedList.map(item => item.value);', selectedList.map(item => item.value))
    contact.ownerid = selectedList.map(item => item.value);
    //console.log('lead.ownerid', lead.ownerid)
    // handleChange({ target: { name: 'product', value: selectedList.map(item => item.value) } });
  };

  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setContact({ ...contact, state: e.value, city: "" });
  };

  const handleSelectListChange = (value, name) => {
    ////console.log("contact:", contact);
    setContact({ ...contact, [name]: value.value });
    setSelectedCity(value.value);
  };

  {
    contact.id !== null && name === "" ? (
      setName(
        contact.firstname + " " + contact.lastname
      )
    ) : (
      <h1></h1>
    );
  }
  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const checkRequredFields = () => {
    //Created a REGEX for the email validation 13 Sept 2024 @ali 
    const flags = "g";
    const pattern = "^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}$";
    const regexPattern = new RegExp(pattern, flags);
    if (selectOwner && selectOwner?.length === 0) {
      setShow(true);
      // document.querySelector(".username").style.border = "1px solid red";
    } else if (
      contact.firstname &&
      contact.firstname.trim() !== "" &&
      contact.lastname &&
      contact.lastname.trim() !== "" &&
      contact.phone &&
      contact.phone.trim() !== "" &&
      contact.phone.length == 10 &&
      contact.title &&
      contact.title.trim() !== "" &&

      contact.ownerid && contact?.ownerid?.length > 0
      && contact.area && contact.area.trim() !== ""
    ) {
      //For valid the email if email found 13 Sept 2024 @ali 
      if (contact.email && contact.email != "" && !contact.email.match(regexPattern)) return true;

      let areaError = true;
      let titleError = true;

      if (contact.area === "Other") {
        if (contact.otherarea && contact.otherarea.trim() !== "") areaError = false;
      } else {
        contact.otherarea = "";
        areaError = false;
      }

      if (contact.title === "Other") {
        if (contact.othertitle && contact.othertitle.trim() !== "") titleError = false;
      } else {
        contact.othertitle = "";
        titleError = false;
      }

      return areaError || titleError
    }
    return true;
  };

  const handleSubmit = async (e) => {
    console.log('contact', contact)
    e.preventDefault();

    if (checkRequredFields()) {
      setValidated(true);
      return;
    }


    //========= Logic to perform Create or Edit ======
    let result = {};
    if (contact.id) {
      result = await SparkApi.saveContact(contact);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/contacts/${contact.id}`, { state: contact });
      }
    } else {
      result = await SparkApi.createContact(contact);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/contacts/${result.id}`, { state: result });
      }
    }
  };

  const handleCancel = () => {
    if (contact.id) {
      navigate("/contacts/" + contact.id, { state: contact });
    } else {
      navigate(`/contacts`)
    }

  };
  return (
    <Container className="view-form">
      <Row >
        <Col></Col>
        <Col lg={8} className="pb-1 pt-2">
          <Link className="nav-link" to="/contacts">
            Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Contacts</div>
          </Link>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >

            <Row className="view-form-header align-items-center">
              <Col lg={8}>
                {contact.id ? (
                  <>
                    <h5>Edit Contact</h5>
                    <h6>{contact.firstname}</h6>
                  </>
                ) : (
                  <h5>Create Contact</h5>
                )}


              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row className="ibs-edit-form pb-3 ">
              <Col>
                <Alert variant="danger" show={show} className="error-alert my-3 mx-2">
                  Please select assign staff
                </Alert>
              </Col>

              <Row className="">
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Account Name
                    </Form.Label>
                    <Select

                      placeholder="Select Account Name"
                      value={option}
                      onChange={handleAccount}
                      options={optionAccount}
                    //value={salutationValue}
                    ></Select>
                    <Form.Control.Feedback type="invalid">
                      Please select account name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLeadSource"
                    >
                      Contact Type
                    </Form.Label>

                    <Form.Select aria-label="Enter status" required value={contact.title} name="title" onChange={handleChange}>
                      <option value="">--Select Source--</option>
                      <option value="Architect">Architect</option>
                      <option value="Interior Designer">Interior Designer</option>
                      <option value="Contractor">Contractor</option>
                      <option value="Dealer">Dealer</option>
                      <option value="Client">Client</option>
                      <option value="Google">Google</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Whatsapp">Whatsapp</option>
                      <option value="Email">Email</option>
                      <option value="Cold Calling">Cold Calling</option>
                      <option value="Exhibition">Exhibition</option>
                      <option value="Company Person">Company Person</option>
                      <option value="Builder">Builder</option>
                      <option value="Other">Other</option>

                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide contact type.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {contact?.title === 'Other' &&
                  <Col lg={6} >
                    <Form.Group className="mx-3" >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Other Type
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="othertitle"
                        placeholder="Enter Other Type"
                        value={contact.othertitle}
                        //defaultValue="Ali"
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide other type.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>}


                <Col lg={6} className="">
                  <Row className="px-3">
                    <Col lg={4}>

                      <Form.Group className="" controlId="formBasicsalutation">
                        <Form.Label className="form-view-label" htmlFor="formBasicsalutation">
                          Salutation
                        </Form.Label>
                        <Form.Control
                          style={{ height: "36px" }}
                          as="select"
                          name="salutation"
                          value={contact.salutation}
                          onChange={handleChange}
                        >
                          <option value="">---Select---</option>
                          <option value="Mr">Mr.</option>
                          <option value="Mrs">Mrs.</option>
                          <option value="Miss">Miss</option>
                          <option value="Ms">Ms.</option>
                          <option value="Dr">Dr.</option>
                          <option value="Prof">Prof.</option>

                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={8}>
                      <Form.Group controlId="formBasicFirstName">
                        <Form.Label
                          className="form-view-label"
                          htmlFor="formBasicFirstName"
                        >
                          First Name
                        </Form.Label>
                        <Form.Control
                          style={{ height: "36px" }}
                          required
                          type="text"
                          name="firstname"
                          placeholder="Enter First Name"
                          value={contact.firstname}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide first name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>



                <Col lg={6}>
                  <Form.Group controlId="formBasicLastName" className="mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLastName"
                    // style={{ marginLeft: '10px' }}
                    >
                      Last Name
                    </Form.Label>
                    <Form.Control
                      style={{
                        height: "36px",
                        //  marginLeft: '10px',
                        width: '100%'
                      }}
                      required
                      type="text"
                      name="lastname"
                      placeholder="Enter Last Name"
                      value={contact.lastname}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide last name.
                    </Form.Control.Feedback>
                  </Form.Group>

                </Col>


                {/* </Row>

              <Row> */}
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicEmail">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      value={contact.email}
                      onChange={handleChange}
                      pattern="^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}$"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Phone
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      required
                      type="phone"
                      minLength="10"
                      maxLength="10"
                      name="phone"
                      placeholder="Enter Phone"
                      onKeyDownCapture={e => { (e.keyCode == 32 || ((e.key.length == 1) && isNaN(e.key))) && e.preventDefault() }}
                      value={contact.phone}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide valid phone.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* </Row>

              <Row> */}
                <Col lg={6} >
                  <Form.Group className="mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Assigned User
                    </Form.Label>

                    <MultiSelect
                      required
                      className={`multi-staff custom-select rounded-3 ${show ? 'border-start-2 border-danger' : 'border-start-2 border-success'} `}
                      options={selectUser}
                      value={selectOwner}
                      onChange={handleOwnerSelect}
                      labelledBy="-- Select Assigned--"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide assign staff.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicState">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicState"
                    >
                      State
                    </Form.Label>
                    <Select
                      placeholder="Select State"
                      value={contact.state && {
                        label: contact.state,
                        value: contact.state,
                      }}
                      onChange={handleState}
                      options={state}
                    //value={salutationValue}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCity">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCity"
                    >
                      City
                    </Form.Label>
                    <Select
                      options={cities}
                      onChange={(e) => {
                        handleSelectListChange(e, "city");
                      }}
                      name="city"
                      value={contact.city && { label: contact.city, value: contact.city }}
                      placeholder="Select City"
                    />
                  </Form.Group>
                </Col>


                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPin">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPin"
                    >
                      Pincode
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="pincode"
                      placeholder="Enter Pincode"
                      value={contact.pincode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCountry">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCountry"
                    >
                      Country
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="country"
                      placeholder="Enter Country"
                      value={contact.country}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicStreet">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStreet"
                    >
                      Street
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="street"
                      placeholder="Enter Street"
                      value={contact.street}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6} >
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCompany"
                    >
                      Area
                    </Form.Label>
                    <Form.Select
                      style={{ height: "36px" }}
                      required
                      value={contact.area}
                      name="area"
                      onChange={handleChange}
                    >
                      <option value="">--Select Area--</option>
                      <option value="VKI & Sikar road">VKI & Sikar road</option>
                      <option value="Vidyadhar nagar">Vidyadhar nagar</option>
                      <option value="Bani park">Bani park</option>
                      <option value="Vaishali Nagar">Vaishali Nagar</option>
                      <option value="Vaishali Nagar-West">Vaishali Nagar-West</option>
                      <option value="Mansarovar">Mansarovar</option>
                      <option value="Patrakar colony">Patrakar colony</option>
                      <option value="Sanganer">Sanganer</option>
                      <option value="Gopalpura bypass">Gopalpura bypass</option>
                      <option value="Shyam nagar/Nirman nagar">Shyam nagar/Nirman nagar</option>
                      <option value="Durgapura">Durgapura</option>
                      <option value="JLN Marg">JLN Marg</option>
                      <option value="Malviya Nagar">Malviya Nagar</option>
                      <option value="Bapu Nagar-Lal kothi">Bapu Nagar-Lal kothi</option>
                      <option value="Jagatpura">Jagatpura</option>
                      <option value="C-Scheme-Civil lines">C-Scheme-Civil lines</option>
                      <option value="Jawahar nagar-Raja park">Jawahar nagar-Raja park</option>
                      <option value="Old city">Old city</option>
                      <option value="Amer-Delhi road">Amer-Delhi road</option>
                      <option value="Jhotwara">Jhotwara</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide area.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {contact?.area === 'Other' &&
                  <Col lg={6} >
                    <Form.Group className="mx-3" >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Other Area
                      </Form.Label>
                      <Form.Control
                        style={{ height: "36px" }}
                        required
                        type="text"
                        name="otherarea"
                        placeholder="Enter Other Area"
                        value={contact.otherarea}
                        //defaultValue="Ali"
                        onChange={(e) => handleChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide other area
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>}

                <Col lg={6}>
                  <Form.Group className="mx-3">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Date of Birth
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="Date"
                      name="dob"
                      placeholder="Enter DOB"
                      max={moment(new Date()).format('YYYY-MM-DD')}
                      value={contact?.dob ? moment(contact.dob).format('YYYY-MM-DD') : ''}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6} >
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLastName"
                    >
                      Anniversary
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="date"
                      name="anniversary"
                      placeholder="Enter Anniversary"
                      value={contact?.anniversary ? moment(contact.anniversary).format('YYYY-MM-DD') : ''}
                      max={moment(new Date()).format('YYYY-MM-DD')}
                      onChange={(e) => handleChange(e)}
                    />

                  </Form.Group>
                </Col>
                <Col lg={6} >
                  <Form.Group className="mx-3" controlId="formBasicwebsite">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicwebsite"
                    >
                      Website
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="website"
                      placeholder="Enter Website"
                      value={contact.website}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

              </Row>

            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container >
  );
};

export default ContactEdit;