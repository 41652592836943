

import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import parse from 'html-react-parser'
import SparkApi from "../../api/SparkApi";


const Notification = ({ refreshNotification, showNotification, closeNotification, notifications, openNotificationArea,notificationBox }) => {

  const [show, setShow] = useState(showNotification);
  const [messages, setMessages] = useState(notifications);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("aa gya");

  }, [refreshNotification]);

  useEffect(() => {
    //console.log("aa gya1", notifications);
    let tempMessages = JSON.parse(JSON.stringify(notifications));
    setMessages(tempMessages);
  }, []);

  const markReadNotification = (item) => {
    //console.log(item)
    let tempArr = messages.filter((msg) => {
      if (msg.id === item.id)
        msg.status = 'Read';

      return true
    });

    //console.log(tempArr)
    setMessages(tempArr)
    //setRefreshNotification(new Date());
    //closeNotification(item);


    markReadMsg(item.id);
  };

  async function markReadMsg(notificationid) {

    let tasks = await SparkApi.markReadMessage(notificationid);


  }

  async function openRecord(notification) {
    if (notification.status === 'New')
      await SparkApi.markReadMessage(notification.id);

    closeNotification();
    // console.log("notification",notification)
    if (notification.parenttype == 'Lead')
      navigate(`/leads/${notification.parentid}`);
    else if(notification.parenttype == 'Task'){
      const taskDetail =  await SparkApi.getTaskById(notification.parentid);
      console.log("TASK of message",taskDetail);
      switch (taskDetail.parenttable) {
        case "lead":
          navigate(`/leads/${taskDetail.parentid}`);
          break;
        case "contact":
          navigate(`/contacts/${taskDetail.parentid}`);
          break;
        case "account":
          navigate(`/accounts/${taskDetail.parentid}`);
          break;
        case "business":
          navigate(`/business/${taskDetail.parentid}`);
          break;
        default:
          // navigate(`/leads/${taskDetail.parentid}`);
          break;
      }
    }




  }

  async function deleteNotification(notification) {

    let tasks = await SparkApi.deletePushNotification(notification.id);
    //console.log(notification)
    let tempArr = messages.filter((msg) => {
      return msg.id !== notification.id;
    });

    //console.log(tempArr)
    setMessages(tempArr)


  }

  return (


    <div className="notification-area"
      onMouseLeave={openNotificationArea}
      style={{ overflowY: "auto", height: "81vh", width: "auto", scrollbarWidth: "thin" }}
      ref={notificationBox}
    >
      {messages?.length === 0 && <Alert variant="success"> <p>No new notifications</p></Alert>}
      {messages?.length > 0 && messages.map((item) => (



        <Alert style={{ width: "400px", paddingTop: ".5rem" }} variant="success" className="">
          <Alert.Heading><div className="d-flex align-items-center justify-content-between">
            <div
              style={{
                fontSize: "smaller",
                borderBottom: "1px solid #17191c33",
                paddingBottom: ".2rem",
                marginBottom: ".5rem",
              }}
            >
              <span
                className="d-inline"
                style={{
                  border: "none",
                  color: "#e6e6e6",
                  fontSize: "1rem",
                  color: "#645721",
                }}
              >
                {item.createdbyname}
              </span>
              <span
                className="d-inline"
                style={{
                  paddingLeft: "1rem",
                  color: "#645721",
                  fontSize: ".8rem",
                  border: "none",
                }}
              >
                {item.date}
              </span>

            </div>
            <div>
              {item.status === 'New' &&
                <span>
                  <i class="fa-regular fa-envelope cursor-pointer mx-3" onClick={() => markReadNotification(item)} title="Mark as read"></i>

                </span>}
              {item.status === 'Read' &&
                <span>
                  <i class="fa-regular fa-envelope-open mx-3" title="Already read"></i>

                </span>}
              <span>
                <i class="fa-regular fa-trash-can cursor-pointer" onClick={() => deleteNotification(item)} title="Delete"></i>
              </span>
            </div>
          </div>
          </Alert.Heading>
          <p>
            {item.status === 'New' && <div className="unread cursor-pointer" onClick={() => openRecord(item)}>{parse(item.description)}</div>}
            {item.status === 'Read' && <>{parse(item.description)}</>}

          </p>
          <div className="cursor-pointer unread mt-4" onClick={() => openRecord(item)}>Open Record <i class="fa-solid fa-arrow-up-right-from-square mx-1"></i></div>
        </Alert>

      ))}
    </div>
  )
}
export default Notification;
