import React, { useEffect, useRef, useState } from "react";
import { Alert, Badge, Button, Image, Modal, NavDropdown, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate, useOutletContext } from "react-router-dom";
import authApi from "../../api/authApi";
import jwt_decode from "jwt-decode";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import { MenuItem } from "react-bootstrap-typeahead";
import Notification from "../common/Notification";
import SparkApi from "../../api/SparkApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PubSub from "pubsub-js";
import { Shimmer } from "react-shimmer";

const Header = ({ socket, leadCreationIndicator }) => {


  const [refreshNotification, setRefreshNotification] = useState(new Date());
  const [showBellAlert, setShowBellAlert] = useState(false);
  const [unreadMsgCount, setUnreadMsgCount] = useState(0);
  const [messages, setMessages] = useState(0);

  const location = useLocation();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [remarks, setRemarks] = useState();
  const [result, setResult] = useState()
  const [buttonDisabled, setButtonDisabled] = useState();
  const [buttonEndDisabled, setButtonEndDisabled] = useState();
  const [locationRecord, setLocationRecord] = useState({});
  const [currentrecord, setCurrentrecord] = useState();
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [address, setAddress] = useState();

  const [sidebar, setSidebar] = useState(false);
  const [imgBody, setImgBody] = useState();

  const [showNotification, setShowNotification] = useState(false);

  const navigate = useNavigate();
  const notificationBox = useRef(null);

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (!localStorage.getItem("token")) navigate("/login");
    if (localStorage.getItem("notifications") && JSON.parse(localStorage.getItem("notifications")).length > 0) {
      navigate("/maintanance");
    }

    try {
      setUserInfo(jwt_decode(localStorage.getItem('token')));
      //console.log(userInfo.username);
    } catch (error) {
      //console.log(error)
    }

    console.log("socket =>", socket)
    socket?.on("greetings", (data) => {
      console.log("greetings mesg received ", data);
      messageList(false);
    })

  }, [socket]);


  // useEffect(() => {
  //   console.log("leadCreationIndicator", leadCreationIndicator);
  //   (async () => {
  //     if (userInfo) {
  //       let count = await SparkApi.getCountOfTodayLead();
  //       console.log("getCountOfTodayLead", count, userInfo?.userrole);
  //       if (userInfo?.userrole == "SUPER_ADMIN") {
  //         if (count && count == 5) {
  //           console.log("getCountOfTodayLead",buttonEndDisabled,count)
  //           buttonEndDisabled == false && handleCheckOut();
  //         }
  //       }
  //       else if (userInfo?.userrole == "ADMIN") {
  //         if (count && count == 10) {
  //           buttonEndDisabled == false && handleCheckOut();
  //         }
  //       }
  //       else if (userInfo?.userrole == "USER") {
  //         if (count && count == 20) {
  //           buttonEndDisabled == false && handleCheckOut();
  //         }
  //       }
  //     }
  //   })();
  // }, [leadCreationIndicator, userInfo]);

  // useEffect(() => {
  //   console.log("Start", buttonDisabled);
  //   buttonDisabled == false && handleCheckIn();
  //   // console.log("Start ",startButton.current)
  //   // startButton.current.disabled  || startButton.current.dispatchEvent(new Event("click"))
  // }, [buttonDisabled]);

  useEffect(() => {
    //console.log(unreadMsgCount)
    //if(unreadMsgCount > 0)
    //setShowBellAlert(true);
  }, [unreadMsgCount]);

  const formatMessage = (mystr) => {

    let oldStr = mystr;
    mystr = mystr.substring(1);
    var arry = mystr.split(" @");
    var returnArr = [];

    for (var a of arry) {
      var obj = {};
      var value = a.substring(a.lastIndexOf("[") + 1, a.lastIndexOf("]"));
      var key = a.substring(a.lastIndexOf("(") + 1, a.lastIndexOf(")"));
      obj[`username`] = value;
      obj[`id`] = key;
      if (key)
        returnArr.push(obj);
    }
    //console.log(returnArr)
    for (var a of returnArr) {
      //console.log(`@[${a.username}](${a.id})`)
      oldStr = oldStr.replace(`@[${a.username}](${a.id})`, `<a href="/users/${a.id}">${a.username}</a>`);
    }

    return oldStr;
  };


  const messageList = (open) => {
    async function init() {
      let tasks = await SparkApi.fetchUnreadMessages();

      // console.log("tasks", tasks);




      if (tasks && tasks?.length > 0) {
        tasks.forEach(msg => {
          msg.description = formatMessage(msg.description);
        });

        setUnreadMsgCount(tasks.length);
        setMessages(tasks)
        setShowNotification(open);
        setShowBellAlert(!open);
        playBellSound(); // Play the bell sound
      } else {
        //console.log('No data')
        setMessages([])
        setUnreadMsgCount(0);
        setShowNotification(open);
      }
    }
    init();
  };

  const playBellSound = () => {
    const audio = new Audio('/RZFWLXE-bell-hop-bell.mp3');
    audio.play();
  };



  const logout = () => {
    authApi.logout();
    navigate("/login");
  };

  const toggleSidebar = () => {
    //  if(sidebar === false){
    //setSidebar(true);
    //////console.log(sidebar);
    document.querySelector("#sidebar").classList.toggle("hide");
    document.querySelector("#sidebar").classList.toggle("show");
    //document.querySelector("#minisidebar").classList.toggle("active");
    //}

    //  else{
    //setSidebar(false);
    //////console.log(sidebar);
    // document.querySelector("#sidebar").classList.toggle("hide");
    // document.querySelector("#sidebar").classList.toggle("show");
    //document.querySelector("#minisidebar").classList.toggle("active");
    // }

  };



  const closeNotification = () => {
    setShowNotification(false)

  };

  const openNotificationArea = (e) => {
    // console.log("showNotification", showNotification, e.type,e )
  
    if (!showNotification){
      messageList(true);
    }
    else{
      setShowNotification(false);    
    }
    setShowBellAlert(false);



    //setRefreshNotification(new Date())
  }



  const startButton = useRef(null);
  const endButton = useRef(null);



  useEffect(() => {
    if (alert) {
      setTimeout(() => {
      }, 3000)
    }
  }, [alert])

  console.log('locationRecord', locationRecord)

  useEffect(() => {
    async function init() {
      let userdata = await SparkApi.getCurrentUserTrackingRec();
      console.log('userdata:', userdata);
      console.log('fdsdf', moment(userdata?.nextlogindate).format('DD-MM-YYYY'))
      //console.log(moment(userdata?.nextlogindate).format('DD-MM-YYYY') >   moment(new Date()).format('DD-MM-YYYY'))
      //console.log( moment(new Date()).format('DD-MM-YYYY'))
      setCurrentrecord(userdata);
      setLocationRecord(userdata);
      if (userdata?.errors) {
        setButtonDisabled(false)
        setButtonEndDisabled(true)
      } else {
        if (userdata.logindatetime != null && userdata.logoutdatetime == null && userdata?.isdisabled === false) {
          console.log('yes1')
          setButtonDisabled(true)
          setButtonEndDisabled(false)
          setRemarks(userdata.remarks);
        } else if (userdata.logindatetime == null && userdata.logoutdatetime == null && userdata?.isdisabled === false) {
          console.log('yes2')
          setButtonDisabled(false)
          setButtonEndDisabled(true)
        }
        else if (userdata.logindatetime?.length && userdata.logoutdatetime?.length &&
          userdata?.isdisabled === true &&
          moment(userdata?.nextlogindate).isAfter(moment(new Date()), 'day')) {
          console.log('yes3')
          setRemarks(userdata.remarks)
          setButtonDisabled(true)
          setButtonEndDisabled(true)
        } else if (userdata.logindatetime?.length && userdata.logoutdatetime?.length &&
          userdata?.isdisabled === true &&
          moment(userdata?.nextlogindate).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) {
          //console.log('yes4') 
          setRemarks(userdata.remarks)
          setButtonDisabled(false)
          setButtonEndDisabled(true)
        }
        // else{
        //   setButtonDisabled(false)
        //   setButtonEndDisabled(true)
        // }
      }


    }
    init();

    //setSpinner(true);
    if (!window.myMap) {
      ////console.log('!window.myMap')       
      window.myMap = myMap;
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
      //////console.log('refresh:');
    } else {
      ////console.log('window.myMap')      
      myMap();
    }

    setCurrentrecord({ errors: 'No Data' });
    ////console.log('currentRec => ', currentRec);
  }, []);


  const myMap = async () => {
    ////console.log('calling my map=====')



    var mapProp = {
      center: new window.google.maps.LatLng(latitude ? latitude : 12, longitude ? longitude : 12),
      zoom: 14,
    };

    const locate = window.navigator && window.navigator.geolocation;
    const currentCoords = { lat: 0, lng: 0 };

    if (locate) {
      ////console.log('in locate==>')
      locate.getCurrentPosition((position) => {
        ////console.log('in position', position)
        currentCoords.lat = position.coords.latitude;
        currentCoords.lng = position.coords.longitude;
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);

        // setSpinner(false)
        mapProp = {
          center: new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude),
          zoom: 14,
        }

        //  var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);
        ////console.log('location => ',location)
        var geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: currentCoords }, function (results, status) {
          ////console.log(results)
          let cur_address = results && results.length > 0 ? results[0].formatted_address : '';
          setAddress(cur_address);
          var marker = new window.google.maps.Marker({
            //map: map,
            position: { lat: currentCoords.lat, lng: currentCoords.lng },
            title: cur_address,
            content: cur_address,
          });

          var infowindow = new window.google.maps.InfoWindow({
            content: cur_address
          });
          marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              //   map,
            });
          });

        });

      }, (error) => {
        ////console.log('error in location -> ', error) 
      }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      )
    }

    setTimeout(() => {


    }, 500)

  }



  const setLatLongJs = async (checkIn) => {
    const locate = window.navigator && window.navigator.geolocation;
    if (locate) {
      ////console.log('in locate==>');
      ////console.log('lat lon log => ', latitude + ' ' + longitude);
      locate.getCurrentPosition(async (position) => {
        ////console.log('in position', position);    
        if (checkIn) {
          // const logindatetime = moment.tz(new Date(), "Asia/Kolkata").toDate();
          // console.log('logindatetime',logindatetime)
          let res = await SparkApi.createCheckInRecord({ 'logindatetime': moment.tz(new Date(), "Asia/Kolkata").toDate(), location: address, loginlattitude: position.coords.latitude.toString(), loginlongitude: position.coords.longitude.toString(), attandancetype: 'Employee Attendance', isdisabled: false, nextlogindate: moment.tz(new Date(), "Asia/Kolkata").add(1, 'day').toDate() });
          console.log('res', res)

          setTimeout(() => {

            if (res) {
              setCurrentrecord(res);
              setButtonDisabled(true);
              setButtonEndDisabled(false)
              setLocationRecord(res);
              PubSub.publish('RECORD_SAVED_TOAST', { title: 'Attendance Created', message: 'Attendance created successfully' });

            } else {
              setAlert({ message: 'Somthing Went Wrong.', type: 'danger' });
            }
          }, '500')

        } else {
          ////console.log('false',result);

          let res;
          console.log('locationRecord:', locationRecord);

          res = await SparkApi.handlCheckOut({ ...locationRecord, 'logoutdatetime': moment.tz(new Date(), "Asia/Kolkata").toDate(), 'logoutlattitude': position.coords.latitude.toString(), 'logoutlongitude': position.coords.longitude.toString(), location: address, isdisabled: true })
          console.log('res:', res);
          console.log('adsad', moment(locationRecord?.nextlogindate).format('DD-MM-YYYY'))
          console.log('currentrecord', locationRecord)
          console.log('ssf', moment(new Date()).format('DD-MM-YYYY'))
          console.log('ssf', moment(locationRecord?.nextlogindate).format('DD-MM-YYYY') > moment(new Date()).format('DD-MM-YYYY'))
          setTimeout(() => {
            setRemarks("");

            if (res) {
              if (moment(locationRecord?.nextlogindate).isAfter(moment(new Date()), 'day')) {
                console.log('No')
                setButtonDisabled(true)
                setButtonEndDisabled(true)
              } else if (moment(locationRecord?.nextlogindate).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) {
                //console.log('yes',moment(currentrecord?.nextlogindate).format('DD-MM-YYYY'))
                setButtonDisabled(false)
                setButtonEndDisabled(true)
              }
              setAlert({ message: 'You have been Successfully Checked out.', type: 'danger' })
            } else {
              setAlert({ message: 'Somthing Went Wrong.', type: 'danger' })
            }
          }, '500')


        }
      }, (error) => {
        ////console.log('error in location -> ', error) 
      })
    }
  }

  const handleCheckIn = async () => {
    setLocationRecord({});
    setLatLongJs(true);
  }

  const handleCheckOut = async () => {
    if(window.confirm("Do you confirm want to Shift the end?" )){
      setLatLongJs(false);
    }
  }


  return (
    <>
      <Navbar className="header px-2" bg="" expand="lg" variant="" style={{ marginBottom: isMobile ? "1rem" : "0" }}>
        <button
          type="button"
          id="sidebarCollapse"
          className="btn btn-info"
          onClick={toggleSidebar}

        >
          <i className="fas fa-align-left"></i>
        </button>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/myprofile" className="p-0" style={{ fontSize: ".9rem" }}>

              {/* <img src={`https://spark.indicrm.io/images/${userInfo.tenantcode}/users/${userInfo.id}.thumbnail/${Math.random()}`} className="rounded-circle" style={{ height: "30px", width: "30px", objectFit: "cover" }} /> */}
              {/* <img src={localStorage.getItem("myimage")} className="rounded-circle" style={{height: "30px"}}/>  */}
              <Badge style={{ fontSize: ".9rem" }} bg="light" text="dark">{userInfo.username} </Badge>
              <Badge bg="success" style={{ fontSize: ".9rem" }}>{userInfo.userrole} </Badge> </Nav.Link>

            <Nav.Link href="#" className="d-flex p-0" style={{ alignItems: "center" }}><span className="mx-2" style={{ fontSize: ".9rem" }}>Company</span> <Badge style={{ fontSize: ".9rem" }} bg="secondary">USAM </Badge> </Nav.Link>


          </Nav>

          <Nav className="ml-auto d-flex align-items-center ">


            <div class="icon-wrapper">
              <Button ref={startButton} variant="success" disabled={buttonDisabled} className="mx-1" onClick={() => handleCheckIn()} > Start Shift</Button>
              <Button ref={endButton} variant="danger" disabled={buttonEndDisabled} onClick={() => handleCheckOut()}> End Shift</Button>
              <i className="fa-solid fa-bell cic mx-2" onClick={openNotificationArea} style={{ cursor: "pointer", fontSize: "1.5rem" }}></i>
              {showBellAlert && <span class="notify-number">{unreadMsgCount}</span>}
            </div>

            {/* 
          <i className="fa-solid fa-bell  icon-grey" ></i> */}
            {showNotification &&
              <Notification notificationBox={notificationBox} openNotificationArea={openNotificationArea} notifications={messages} closeNotification={closeNotification} refreshNotification={refreshNotification} />}
            {localStorage.getItem("token") ? (
              <Button variant="btn btn-primary" onClick={logout} title="Logout">
                <i className="fa-solid fa-right-from-bracket"></i>
              </Button>
            ) : (
              <></>
            )}

          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {/* <Row className="">
        {alert && <Alert variant={alert.type} onClose={() => setAlert(false)} style={{ width: '100%', padding: '15px', margin: "0px 0px 5px 0px", fontWeight: 'bold', textAlign: 'center', }}>
          {alert.message}
        </Alert>}
        {spinner && <Shimmer height={500} ></Shimmer>}
        {!spinner && <div id="googleMap" style={{ width: '100%', height: '300px', border: '1px solid black', display: "none" }}>

        </div>}
      </Row> */}

    </>
  );
};

export default Header;
