import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import moment from "moment";
import SparkApi from "../api/SparkApi";
import Select from "react-select";
import jwt_decode from "jwt-decode";

const TaskEdit = (props) => {
    // console.log("TASK PROPS",props)
    const [validated, setValidated] = useState(false);
    const [option, setOption] = useState();
    const [location, setLocation] = useState(useLocation());
    const [navigate, setNavigate] = useState(useNavigate());
    const [task, setTask] = useState(props.task ? props.task.row : { parentid: props.parentid,parenttable: props.parenttable });
    const [taskTargetDate, setTaskTargetDate] = useState();
    const [currentTargetDate, setCurrentTargetDate] = useState();

    useEffect(() => {
        let userInfo = jwt_decode(localStorage.getItem('token'));

        let tempValue = {};
        tempValue.value = userInfo.id;
        tempValue.label = userInfo.username;
        setOption(tempValue);

        if (props.task) {
            let temp = {};
            temp.value = props.task.row.ownerid;
            temp.label = props.task.row.ownername;
            setOption(temp);
        }

        if (props.parentid !== null && props.task !== null) {
            let current = new Date();
            task.lastmodifieddate = moment(current).format('YYYY-MM-DD');
        }

        setSelectedUser(task.ownername);
    }, []);

    const [show, setShow] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [optionUsers, setOptionUsers] = useState([]);
    const [ownerList, setOwnerList] = useState([]);
    const [defaultOwner, setDefaultOwner] = useState([]);
    const [defaultTargetDate, setDefaultTargetDate] = useState(new Date());

    useEffect(() => {
        async function init() {
            const result = await SparkApi.fetchUsers();
            if (result) {
                let ar = result.map((item) => {
                    return {
                        value: item.id,
                        label: item.username
                    };
                });
                setOptionUsers(ar);
            } else {
                setOptionUsers([]);
            }
        }
        init();
    }, []);

    const handleUsers = (event) => {
        setOption(event);
        setSelectedUser(event);
        setTask({ ...task, ownerid: event.value, ownername: event.label });
    }

    const handleChange = (e) => {
        setTask({ ...task, [e.target.name]: e.target.value });
    };

    const checkRequiredFields = () => {
        if (
            task.title && task.title.trim() !== '' &&
            task.status && task.status.trim() !== '' &&
            task.priority && task.priority.trim() !== '' &&
            task.ownerid && task.ownerid.trim() !== ''
        ) {
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        //console.log('targated',task.targetdate);
        if (task.targetdate === undefined) {
            //console.log('if call')
            task.targetdate = moment(task.targetdate).toISOString();
        }
        task.ownerid = option.value;
        task.ownername = option.label;
        // task.targetdate = moment(task.targetdate).toISOString(); // Fixed the issue with formatting the target date
        e.preventDefault();
        if (checkRequiredFields()) {
            setValidated(true);
            return;
        }

        if (task.id) {
            const result = await SparkApi.saveTask(task);
            if (result) {
                submitTasks();
            }
        } else {
            // console.log("TASK PAYLOAD",task)
            const result = await SparkApi.createTask(task);
            if (result) {
                submitTasks();
            }
        }
    };

    const submitTasks = () => {
        props.submitTasks();
    };

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {task.id ? 'Edit Task' : 'Create Task'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                    <Row>
                        <Col lg={12}>
                            <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit} controlId="taskEdit">
                                <Row>
                                    <Alert variant="danger" show={show} className="error-alert">
                                        Please Select Assign Staff
                                    </Alert>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                                Title
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="title"
                                                placeholder="Enter title"
                                                value={task.title}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicType">
                                            <Form.Label className="form-view-label" htmlFor="formBasicType">
                                                Type
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Enter Payment Status"
                                                name="type"
                                                value={task.type}
                                                onChange={handleChange}
                                            >
                                                <option value=''>--Select Type--</option>
                                                <option value="Call">Call</option>
                                                <option value="Task">Task</option>
                                                <option value="Email">Email</option>
                                                <option value="Meeting">Meeting</option>
                                                <option value="Notes">Notes</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicPriority">
                                            <Form.Label className="form-view-label" htmlFor="formBasicPriority">
                                                Priority
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter Priority" name="priority" value={task.priority} onChange={handleChange}>
                                                <option value="">--Select Priority--</option>
                                                <option value="Low">Low</option>
                                                <option value="Normal">Normal</option>
                                                <option value="High">High</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label className="form-view-label" htmlFor="formBasicStatus">
                                                Status
                                            </Form.Label>
                                            <Form.Select required aria-label="Enter status" name="status" value={task.status} onChange={handleChange}>
                                                <option value="">--Select Status--</option>
                                                {/* <option value="Not Started">Not Started</option>
                                                <option value="In Progress">In Progress </option>
                                                <option value="Completed">Completed</option>
                                                <option value="Waiting">Waiting</option>
                                                <option value="Deferred">Deferred</option> */}
                                                <option value="1st Call">1st Call</option>
                                                <option value="Call Attempt Activity">Call Attempt Activity</option>
                                                <option value="Callback">Callback</option>
                                                <option value="Follow-Up">Follow-Up</option>
                                                <option value="WhatsApp Brochure Shared">WhatsApp Brochure Shared</option>
                                                <option value="Lead Not Enquired">Lead Not Enquired</option>
                                                <option value="Future Follow-Up">Future Follow-Up</option>
                                                <option value="Do Not Disturb">Do Not Disturb</option>
                                                <option value="Completed">Completed</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicTargetdate">
                                            <Form.Label className="form-view-label" htmlFor="formBasicTargetdate">
                                                Target date
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="date"
                                                name="targetdate"
                                                placeholder="Enter targetdate"
                                                value={moment(task.targetdate).format('YYYY-MM-DD')}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mx-3" controlId="formBasicOwner">
                                            <Form.Label className="form-view-label" htmlFor="formBasicOwner">
                                                Assign Staff
                                            </Form.Label>
                                            <Select
                                                required
                                                name="ownerid"
                                                value={option}
                                                className="custom-select username"
                                                onChange={handleUsers}
                                                options={optionUsers}
                                                getOptionValue={(option) => option.value}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mx-3" controlId="formBasicDescription">
                                            <Form.Label className="form-view-label" htmlFor="formBasicDescription">
                                                Description
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="description"
                                                placeholder="Enter description"
                                                value={task.description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit}>Save</Button>
                <Button onClick={props.onHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskEdit;
