/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row, Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import PubSub from 'pubsub-js';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CityState from "../constants/CityState.json";
import * as constants from '../constants/CONSTANT';
import jwt_decode from "jwt-decode";
import { MultiSelect } from "react-multi-select-component";

const BusinessEdit = () => {
    const [validated, setValidated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [optionContact, setOptionContact] = useState({});
    const [selectedBusinessSource, setSelectedBusinessSource] = useState({});
    const [selectStatus, setSelectStatus] = useState({});
    const [selectIndustry, setSelectIndustry] = useState({});
    const [selectSalutation, setSalutation] = useState({});
    const [lostReason, setLostReason] = useState(false);
    const [selectUser, setSelectUser] = useState({});
    const [name, setName] = useState("");
    const [state, setState] = useState({});
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [option, setoption] = useState();
    const [selectOwner, setOwnerSelect] = useState([]);

    //const [rating,setRating]=useState([{}]);
    const industryValue = [
        { value: "", label: "--None--" },
        { value: "Agriculture", label: "Agriculture" },
        { value: "Apparel", label: "Apparel" },
        { value: "Banking", label: "Banking" },
        { value: "Chemicals", label: "Chemicals" },
        { value: "Communications", label: "Communications" },
    ];

    const leadSource = [
        { value: "Web", label: "Web" },
        { value: "Phone Enquiry", label: "Phone Enquiry" },
        { value: "Partner Referral", label: "Partner Referral" },
        { value: "Purchased List", label: "Purchased List" },
        { value: "Other", label: "Other" }
    ];
    const [show, setShow] = React.useState(false);

    const [business, setBusiness] = useState(location.state ? location.state : {});

    // ////console.log(:)
    let userInfo;
    useEffect(() => {
        let userInfo = jwt_decode(localStorage.getItem('token'));
        ////console.log('userInfo', userInfo);



        if (location?.state) {
            ////console.log(location.state)

            let source = leadSource.filter(source => source.value === location.state.leadSource)
            let industry = industryValue.filter(industry => industry.value === location.state.industry)

            ////console.log('source',businessStatus)

            setSelectedBusinessSource(source[0]);
            setSelectIndustry(industry[0]);

            setLostReason(location.state.iswon === false);

            ////console.log('Business Data ', location.state)
            if (business.id) {
                let temp = {}
                temp.value = location.state.ownerid;
                temp.label = location.state.ownername;
                setoption(temp);
            } else {
                let temp = {};
                temp.value = userInfo.id;
                temp.label = userInfo.username;
                setoption(temp);
                business.ownername = userInfo.username;
                business.ownerid = userInfo.id;
            }

        } else {

            let temp = {};
            temp.value = userInfo.id;
            temp.label = userInfo.username;
            setoption(temp);
            business.ownername = userInfo.username;
            business.ownerid = userInfo.id;

        }

        async function init() {
            const fetchUser = await SparkApi.fetchUsers();
            ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
            let usr = []
            fetchUser.map((item) => {
                var obj = {};
                obj.value = item.id;
                obj.label = item.username + " - " + item.usertype;
                obj.usertype = item.usertype;
                ////console.log(" obj.label >" + obj.label)
                usr.push(obj);
            })
            console.log('usr', usr)
            setSelectUser(usr);
            if (location?.state?.ownerid) {
                const initialSelection = location?.state?.ownerid.map(id => ({
                    value: id,
                    label: usr.find(user => user.value === id)?.label || '' + " - " + usr.find(user => user.value === id)?.usertype || '',
                    usertype: usr.find(user => user.value === id)?.usertype || ''
                }));
                setOwnerSelect(initialSelection);
            }
            //////console.log('CityState',CityState)
            let st = [];

            CityState.map((item) => {
                //////console.log(item.state,item.id);
                var obj = {};
                obj.value = item.state;
                obj.label = item.state;
                //////console.log(" obj.label >"+ obj.label)
                st.push(obj);

            });
            let finalStates = {};
            st = st.filter(function (currentObject) {
                if (currentObject.value in finalStates) {
                    return false;
                } else {
                    finalStates[currentObject.value] = true;
                    return true;
                }
            });
            //console.log('st:', st);
            setState(st);
        }

        init();


    }, []);

    const handleOwnerSelect = (selectedList) => {
        console.log('selectedList', selectedList)
        setOwnerSelect(selectedList);
        console.log('selectedList.map(item => item.value);', selectedList.map(item => item.value))
        business.ownerid = selectedList.map(item => item.value);
        // handleChange({ target: { name: 'product', value: selectedList.map(item => item.value) } });
    };

    const handleState = (e) => {
        let filteredCities = [];
        CityState.forEach(function (obj) {
            if (obj.state === e.value) {
                filteredCities.push({
                    label: obj.name,
                    value: obj.name
                })
            }
        });
        setCities(filteredCities);
        setBusiness({ ...business, 'state': e.value });
    }

    const handleSelectListChange = (value, name) => {
        ////console.log('contact:', business);
        setBusiness({ ...business, [name]: value.value });
        setSelectedCity(value.value);

    }
    //Access all the value input values 
    const handleChange = (e) => {
        ////console.log("e.target : ", e.target)
        let temp = { ...business }
        ////console.log("temp : ", temp)
        setBusiness({ ...business, [e.target.name]: e.target.value });


        ////console.log("business:", business);

    };

    const handleRoleChange = (e) => {
        ////console.log('event', e)
        setoption(e)
        setBusiness({ ...business, 'ownerid': e.value, ownername: e.label });
    }


    const handleSubmit = async (e) => {
        console.log('handle submit call', business)
        e.preventDefault();

        if (checkRequredFields()) {
            setValidated(true);
            return;
        }



        //========= Logic to perform Create or Edit ======
        let result = {};



        if (business.id) {
            //console.log("business.id: ", business);
            ////console.log("business: ", JSON.stringify(business));
            result = await SparkApi.updateBusiness(business);
            //console.log("result ===>", result);
            if (result.success) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                navigate(`/business/${business.id}`, { state: business });
            }
        } else {
            /*
            result = await SparkApi.createBusiness(business);
            ////console.log('result', result)
            if (result) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                navigate(`/businesss/${result.id}`, { state: result });
            }
            */
        }
    };

    const checkRequredFields = () => {
        console.log("Business =>", business)
        if (selectOwner && selectOwner?.length === 0) {
            ////console.log('if call');
            setShow(true);
            document.querySelector(".username").style.border = "1px solid red";
        } else if (
            business.name &&
            business.name?.trim() !== ''
            &&
            (business?.amount) &&
            // business.amount?.trim() !== ''  &&
            business.fulladvance &&
            business.fulladvance?.trim() !== ''

        ) {
            return false;
        }
        return true;
    }

    const handleCancel = () => {
        navigate("/business/", { state: business });
    };

    return (
        <Container className="view-form">
            <Row >
                <Col></Col>
                <Col lg={8} className="pb-1 pt-2">
                    <Link className="nav-link" to="/business">
                        Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Business</div>
                    </Link>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3" validated={validated} onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={9}>
                                {
                                    business.name === "" ? <h6>Create Business</h6> : <h6> Edit Business</h6>
                                }

                                <h5>{business.name}</h5>
                            </Col>
                            <Col lg={3} >
                                <Stack direction="horizontal" className="float-end" >
                                    <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                        Save
                                    </Button>
                                    <Button
                                        className="btn-sm"
                                        variant="danger"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="ibs-edit-form mb-4" lg={12}>

                            <Alert variant="danger" show={show} className="error-alert my-3 mx-2">
                                Please Select Assign Staff
                            </Alert>



                            <Col lg={6}>
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Name
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        required={true}
                                        placeholder="Enter Name"
                                        value={business.name}

                                        onChange={(e) => handleChange(e)}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>



                            <Col lg={6}>
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicFirstName"
                                >
                                    Contact
                                </Form.Label>
                                {/* <Link className="form-control disabled" style={{ backgroundColor: "#ccc", color: "blue" }} to={"/contacts/" + business.contactid}>
                                    {business.contactname}
                                </Link> */}
                                <Link className="form-control disabled" style={{ padding: 0 }} to={business.contactid && "/contacts/" + business.contactid}>
                                    <Form.Control type="none" value={business.contactname} style={{ backgroundColor: "#ccc", color: "blue", cursor: "pointer" }} readOnly />
                                </Link>

                            </Col>

                            <Col lg={6}>
                                <Form.Label
                                    className="form-view-label"
                                    htmlFor="formBasicFirstName"
                                >
                                    Account
                                </Form.Label>
                                <Link className="form-control disabled" style={{ padding: 0 }} to={business.accountid && "/accounts/" + business.accountid}>
                                    <Form.Control type="none" value={business.accountname} style={{ backgroundColor: "#ccc", color: "blue", cursor: "pointer" }} readOnly />
                                </Link>

                            </Col>





                            <Col lg={6} >

                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicBusinessSource"
                                    >
                                        Source
                                    </Form.Label>

                                    <Form.Select aria-label="Enter status" value={business.leadsource} name="leadsource" onChange={handleChange}>
                                        <option value="">--Select-Source--</option>
                                        <option value="Architect">Architect</option>
                                        <option value="Interior Designer">Interior Designer</option>
                                        <option value="Contractor">Contractor</option>
                                        <option value="Dealer">Dealer</option>
                                        <option value="Client">Client</option>
                                        <option value="Google">Google</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Email">Email</option>
                                        <option value="Cold Calling">Cold Calling</option>
                                        <option value="Other">Other</option>

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter  Lead Source.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Assigned User
                                    </Form.Label>

                                    <MultiSelect
                                        required
                                        className="multi-staff custom-select username"
                                        options={selectUser}
                                        value={selectOwner}
                                        onChange={handleOwnerSelect}
                                        labelledBy="-- Select Assigned--"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Assigned.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Business Type
                                    </Form.Label>

                                    <Form.Select aria-label="Enter Industry" value={business.industry} name="industry" onChange={handleChange}>
                                        <option value="">--Select-Business Type--</option>
                                        <option value="Residencial">Residencial</option>
                                        <option value="Builders">Builders</option>
                                        <option value="Commercial">Commercial</option>
                                        <option value="Dealers">Dealers</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Enter Business Type.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>



                            {/*  <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Model
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentmodel" onChange={handleChange} value={business.paymentmodel}>
                                        <option value="">--Select Payment Model--</option>

                                        <option value="Subscription">
                                            Subscription
                                        </option>
                                        <option value="One Time">
                                            One Time
                                        </option>



                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Terms
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentterms" onChange={handleChange} value={business.paymentterms}>
                                        <option value="">--Select Terms--</option>
                                        <option value="12">
                                            12 Months
                                        </option>
                                        <option value="24">
                                            24 Months
                                        </option>
                                        <option value="One Time">
                                            One Time
                                        </option>
                                        <option value="One Time with Yearly Renewal">
                                            One Time with Yearly Renewal
                                        </option>


                                    </Form.Select>
                                </Form.Group>
                            </Col> */}
                            <Col lg={6}>
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Full Advance
                                    </Form.Label>
                                    <Form.Control
                                        required={true}
                                        type="text"
                                        name="fulladvance"
                                        placeholder="Enter Full Advance"
                                        value={business.fulladvance}
                                        //defaultValue="Ali"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide full advance.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Percentage of Advance
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="percentageofadvance"
                                        placeholder="Enter Percentage of Advance"
                                        value={business.percentageofadvance}
                                        //defaultValue="Ali"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Percentage of Advance.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Value of Advance
                                    </Form.Label>
                                    <Form.Control

                                        type="text"
                                        name="valueofadvance"
                                        placeholder="Enter Value of Advance"
                                        value={business.valueofadvance}
                                        //defaultValue="Ali"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Enter Value of Advance.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Sales Amount (₹)
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        name="amount"
                                        placeholder="Enter Expected Amount"
                                        value={business.amount}
                                        onChange={(e) => handleChange(e)}
                                        className="remove-arrow"
                                        onKeyDownCapture={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}

                                    />


                                    <Form.Control.Feedback type="invalid">
                                        Please provide sales amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Description
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"

                                        placeholder="Enter Description"
                                        value={business.description}
                                        onChange={handleChange}
                                    />



                                </Form.Group>
                            </Col>



                            <Col className="mt-5"></Col>

                        </Row>

                    </Form>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}
export default BusinessEdit