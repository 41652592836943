import React, { useEffect, useState } from 'react'
import SparkApi from '../api/SparkApi'
import { Badge, Button, Col, Row, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import moment from 'moment';
import { Link } from 'react-router-dom';
export default function RelatedListLeads({ parent }) {
    const [body, setBody] = useState([]);
    // const [header, setHeader] = useState([]);
    const [leadStatusArray, setleadStatusArray] = useState(JSON.parse(localStorage.getItem('lead_status')));

    const getStatusClass = (status) => {
        ////console.log('status',status)
        let accessStatusRec = leadStatusArray.filter((value, index, array) => {
            if (value.label === status) {

                return true
            }

        });
        ////console.log('accessStatusRec',accessStatusRec)
        if (accessStatusRec && accessStatusRec.length > 0) {

            if (accessStatusRec[0].is_converted === true) {
                ////console.log('if isconverted')

                return 'success';
            } else if (accessStatusRec[0].is_lost === true) {
                return 'secondary';
            } else {
                return 'primary';
            }
        } else {
            return 'secondary';
        }
    }
    const fetchLeadsOfAccount = async () => {
        if (parent?.id) {
            const res = await SparkApi.fetchAllLeadOfAccount(parent?.id);
            console.log("res", res);
            if (res) {
               
                setBody(res);
                // setHeader(header)
            } else {
                setBody([]);
                // setHeader([])
            }
        }
    }
    useEffect(() => {
        // console.log("parent",parent);
        fetchLeadsOfAccount();
    }, []);
    let header = [
        {
            title: "Name",
            prop: "leadname",
            isFilterable: true,
            cell: (row) => (
                <Link to={"/leads/" + row.id} state={row}>
                    {row.leadname}
                </Link>
            ),
        },
        { title: "Phone", prop: "phone", isFilterable: true },
        // /* { title: "Email", prop: "email", isFilterable: true }, */
    
        {
            title: "Lead Status",
            prop: "leadstatus",
            isFilterable: true, isSortable: true,
            cell: (row) => {
                return (
                    <Badge bg={getStatusClass(row.leadstatus)} style={{ display: "block", paddingBottom: "5px" }}>
                        {row.leadstatus}
                    </Badge>
                );
    
            },
        },
        /*  { title: "Company", prop: "company", isFilterable: true, isSortable: true }, */
        { title: "Lead Source", prop: "leadsource", isFilterable: true },
        { title: "Lead Type", prop: "leadtype", isFilterable: true },
        { title: "Area", prop: "area", isFilterable: true },
        {
            title: "Created Date", prop: "createddate", isSortable: true, isFilterable: true, cell: (row) => (
                <span>
                    {moment(row.createddate).format('DD-MM-yyyy')}
                </span>
            )
        },
        {
            title: "Assigned",
            prop: "ownername",
            isFilterable: true,
            isSortable: true,
            cell: (row) => {
                console.log("first",row)
                const titleAbbreviations = {
                    "Sales Admin": "S. A.",
                    "Installation Admin": "I. A.",
                    "Accounts Admin": "A. A.",
                    "Installation Engineer": "I. E.",
                    "Business Development Executive": "B. D. E.",
                    "Customer Relationship Executive": "C. R. E.",
                    "Office Coordinator": "O. C.",
                    "Office Executive": "O. E.",
                    "Super_Admin": "Super_Admin" // If no abbreviation is needed
                };


                // Split ownername and ownerid arrays
                const ownerNames = row.ownername ? row.ownername.split(', ') : [];
                const ownerIds = row.ownerid ? row.ownerid : [];

                // Function to shorten titles
                const shortenTitle = (name) => {
                    const [fullName, title] = name.split(' - ');
                    const shortTitle = titleAbbreviations[title.trim()] || title; // Use abbreviation if available
                    return `${fullName} - ${shortTitle}`;
                };

                // Ensure ownerNames and ownerIds arrays are of the same length
                const links = ownerNames.map((name, index) => (
                    <Link
                        key={index} // Use index as key if ownerIds[index] is not reliable
                        to={`/users/${ownerIds[index]}`}
                        state={row}
                        className="align-items-center justify-content-start"
                    >
                        {shortenTitle(name)}<br />
                    </Link>
                ));
                return <span>{links}</span>;
            },
        }
    ]
    return (
        <div>
            {body  ?
                <DatatableWrapper body={body}
                    headers={header} 
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 5
                        }
                    }}
                >
                    {/* <Row className="mb-4">
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >


                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >


                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                        </Col>
                    </Row> */}
                    <Table striped className="related-list-table" responsive="sm">
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Pagination />
                </DatatableWrapper> : ''}
        </div>
    )
}
