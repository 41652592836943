import { useEffect, useState } from 'react';
import { Bar, PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, elements, RadialLinearScale, ArcElement } from 'chart.js';
import SparkApi from '../../api/SparkApi';
import { backgroundColors, random_rgba } from "../common/ChartColor";
import { Button } from 'react-bootstrap';


export default function BusinessChart(props) {
    const { toDate, fromDate, setChartApi } = props;
    const [reportData, setReportData] = useState([]);

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Business Report',
            },
        },
    });

    const [data, setData] = useState({ labels: [], datasets: [{}] });

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        RadialLinearScale,
        ArcElement
    );

    // On props date change

    useEffect(() => {
        console.log("date selection", fromDate, toDate)
        async function fetchChartData1() {
            const result = await SparkApi.fetchChartData({
                "apiName": "business_chart",
                "filterColumn": null,
                "date": null,
                "isMonthWiseFilter": false,
                fromDate,
                toDate
            });
            console.log("APi result on from date & to date:- ", result);

            setReportData(result || []);

        }
        if (toDate && fromDate) fetchChartData1();
    }, [toDate, fromDate]);

    // let tempData;
    async function fetchChartData1() {
        const result = await SparkApi.fetchChartData({
            "apiName": "business_chart",
            "filterColumn": null,
            "date": null,
            "isMonthWiseFilter": false
        });
        console.log("APi result =>", result)
        setReportData(result || []);

    }
    useEffect(() => {
        fetchChartData1();
    }, []);
    const truncateLabel = (label) => {
        return label.length > 15 ? `${label.substring(0, 7)}...` : label;
    };
    useEffect(() => {
        if (reportData && reportData.length != 0) {
            const datasets = [];
            const labels = reportData.map((row) => (props.fullMode ? row["Business Name"] : truncateLabel(row["Business Name"]))) || [];
            let dataset = {};
            dataset.label = "Sales Amount";
            dataset.data = reportData.map((row) => row["Sales Amount"] || 0);
            console.log(dataset);
            datasets.push(dataset);
            dataset = {};

            dataset.label = "Received Amount";
            dataset.data = reportData.map((row) => row["Received Amount"] || 0);
            console.log(dataset);
            datasets.push(dataset);
            dataset = {};

            // dataset.label = "Expected Amount";
            // dataset.data = reportData.map((row) => row["Expected Amount"] || 0);
            // console.log(dataset);
            // datasets.push(dataset);
            // dataset = {};

            dataset.label = "Pending Amount";
            dataset.data = reportData.map((row) => row["Pending Amount"] || 0);
            console.log(dataset);
            datasets.push(dataset);
            dataset = {};

            datasets.forEach((dataset, i) => {
                dataset.backgroundColor = backgroundColors[i];
                // dataset.backgroundColor = random_rgba()

            })

            console.log(datasets)
            datasets.length == 0 && datasets.push({});
            setData({ labels, datasets })
        }
        else {
            setData({ labels: [], datasets: [] });
        }
    }, [reportData]);
    return (
        <>
            {Array.isArray(reportData) && reportData.length != 0 ?
                <>
                    {setChartApi && <Button className='float-end' onClick={() => (setChartApi("business_chart"))}>Full View</Button>}
                    <Bar data={data} options={options} width={150} height={100} />
                </>
                :

                <div style={{ minHeight: "60vh" }} className='d-flex align-items-center justify-content-center'>
                    <div>
                        <h1> No data</h1>
                    </div>
                </div>

            }
        </>
    );
}



